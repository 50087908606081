import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import adminrouter from './router/adminrouter'
import '../src/css/index.css'
import { MotionPlugin } from '@vueuse/motion'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'primevue/resources/themes/aura-light-green/theme.css'
import PrimeVue from 'primevue/config';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import { library } from '@fortawesome/fontawesome-svg-core'
import { createNotivue } from 'notivue';
import 'notivue/notifications.css'
import 'notivue/animations.css'
import Vuesax from "vuesax3"
import 'vuesax3/dist/vuesax.css'
import CkeditorPlugin from '@ckeditor/ckeditor5-vue';
import './css/ckeditor-reset.css'



import 'material-icons/iconfont/material-icons.css';


import { faUserSecret, faMask, faCircleQuestion, faHandshakeSimple, faCircleInfo, faFilter, faBullhorn, faTrash, faPenToSquare, faBox, faInbox, faCheck, faXmark, faBell } from '@fortawesome/free-solid-svg-icons'
import { browserLocalPersistence, setPersistence } from 'firebase/auth'
import { projectAuth } from './firebase/config'
library.add(faUserSecret, faMask, faCircleQuestion, faHandshakeSimple, faCircleInfo, faFilter, faBullhorn, faTrash, faPenToSquare, faBox, faInbox, faCheck, faXmark, faBell)

let app = createApp(App);

const notivue = createNotivue({
    position: 'top-center',
    limit: 1,
    enqueue: false,
    notifications: {
        global: {
            duration: 2000
        }
    }
})
const subdomain = window.location.host.split('.')[0];

let routes;
if (subdomain === 'admin') {
    routes = adminrouter;
} else {
    routes = router;
}

setPersistence(projectAuth, browserLocalPersistence)
    .then(() => {


        app.use(routes)
        app.use(MotionPlugin);
        app.use(PrimeVue);
        app.use(notivue),
        app.use(CkeditorPlugin)

        app.component('font-awesome-icon', FontAwesomeIcon);
        app.component('Accordion', Accordion);
        app.component('AccordionTab', AccordionTab);
        app.use(Vuesax, {
            theme: {
                colors: {
                    primary: '#5b3cc4',
                    success: 'rgb(23, 201, 100)',
                    danger: 'rgb(242, 19, 93)',
                    warning: 'rgb(255, 130, 0)',
                    dark: 'rgb(36, 33, 69)'
                }
            }
        })

        app.mount('#app');


    }).catch(err => {
        console.log(err)
    })