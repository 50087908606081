<template>
  <!-- <Navbar /> -->

  <div class="bg-gray-100 h-full font-NotoSansKhmer w-[64rem] md:w-full">
    <div class=" h-full w-[80rem] md:w-[125%] bg-gray-100 lg:w-[130%] xl:w-[120rem]">
      <div class="flex h-screen w-full">
        <div
          :class="isOpenSidebar ? 'xl:w-[390px] lg:w-[20%] md:w-[10%] transition-all duration-300  ' : 'xl:w-[6rem]  lg:w-[5rem] md:w-[5rem]  transition-all duration-300'"
          class="bg-white/50 my-4  ">
          <div v-if="userDocument" class="">
            <div class="mt-2">
              <div :class="isOpenSidebar ? 'ml-2' : 'justify-center'"
                class="lg:items-center lg:flex  p-2 justify-start">

                <div @click="isOpenSidebar = !isOpenSidebar" class=" cursor-pointer hidden lg:flex">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="size-8 hover:text-gray-800 transition-all ease-in-out"
                    :class="isOpenSidebar ? 'lg:ml-1 transition-all duration-300' : 'transition-all duration-300'">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                  </svg>

                </div>
                <div :class="isOpenSidebar ? 'lg:flex  ' : 'hidden '" class=" flex justify-center">
                  <img class="md:w-14 md:object-contain  md:h-14 h-12 w-12 hidden md:hidden  lg:hidden xl:hidden "
                    src="../assets/image/image 5.png" alt="" />
                  <div
                    class="font-bold md:ml-0 hidden lg:block lg:text-lg xl:block xl:text-xl lg:text-primery1 xl:text-nowrap lg:text-nowrap xl:ml-2 lg:ml-2 md:text-wrap">
                    ម៉ាស្ទ័រអាយធី<span class="text-transparent">_</span>ស៊ីស្ទីម</div>

                </div>
                <div class="lg:hidden  mt-2 flex justify-center">
                  <div>
                    <img class="md:w-14  md:object-contain  md:h-14 h-12 w-12  lg:hidden xl:hidden "
                      src="../assets/image/image 5.png" alt="" />
                    <p class="flex-wrap font-bold mt-2 text-center">ម៉ាស្ទ័រ</p>
                    <p class="flex-wrap font-bold text-center">អាយធី</p>
                    <p class="font-bold text-center">ស៊ីស្ទីម</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-3 space-y-1">
              <div class="">
                <router-link to="/admin"
                  :class="isOpenSidebar ? 'lg:pl-2' : 'xl:justify-center lg:justify-center md:justify-center'"
                  class="text-body flex space-x-2 text-nowrap  text-primery1 md:p-4 p-4 font-semibold  md:justify-center  py-[12px] lg:justify-normal    hover:text-indigo-300   duration-300">

                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="lucide lucide-circle-gauge">
                    <path d="M15.6 2.7a10 10 0 1 0 5.7 5.7" />
                    <circle cx="12" cy="12" r="2" />
                    <path d="M13.4 10.6 19 5" />
                  </svg>
                  <p class="hidden " :class="isOpenSidebar ? 'lg:flex hidden' : 'hidden'">ទំព័រដើម</p>
                </router-link>
              </div>
              <div>

                <router-link
                  :class="isOpenSidebar ? 'lg:pl-2' : 'xl:justify-center lg:justify-center md:justify-center'"
                  class="text-body flex space-x-2 text-nowrap  text-primery1 font-semibold md:p-4 p-4 md:justify-center  py-[12px] lg:justify-normal    hover:text-indigo-300   duration-300"
                  to="/services">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="lucide lucide-boxes">
                    <path
                      d="M2.97 12.92A2 2 0 0 0 2 14.63v3.24a2 2 0 0 0 .97 1.71l3 1.8a2 2 0 0 0 2.06 0L12 19v-5.5l-5-3-4.03 2.42Z" />
                    <path d="m7 16.5-4.74-2.85" />
                    <path d="m7 16.5 5-3" />
                    <path d="M7 16.5v5.17" />
                    <path
                      d="M12 13.5V19l3.97 2.38a2 2 0 0 0 2.06 0l3-1.8a2 2 0 0 0 .97-1.71v-3.24a2 2 0 0 0-.97-1.71L17 10.5l-5 3Z" />
                    <path d="m17 16.5-5-3" />
                    <path d="m17 16.5 4.74-2.85" />
                    <path d="M17 16.5v5.17" />
                    <path
                      d="M7.97 4.42A2 2 0 0 0 7 6.13v4.37l5 3 5-3V6.13a2 2 0 0 0-.97-1.71l-3-1.8a2 2 0 0 0-2.06 0l-3 1.8Z" />
                    <path d="M12 8 7.26 5.15" />
                    <path d="m12 8 4.74-2.85" />
                    <path d="M12 13.5V8" />
                  </svg>
                  <p class="hidden" :class="isOpenSidebar ? 'hidden lg:flex' : 'hidden transition-all '">ប្រភេទសេវាកម្ម
                  </p>
                </router-link>
              </div>

              <div>
                <router-link
                  :class="isOpenSidebar ? 'lg:pl-2' : 'xl:justify-center lg:justify-center md:justify-center'"
                  class="text-body flex space-x-2 text-nowrap  text-primery1 font-semibold md:p-3 p-4  md:justify-center  py-[12px] lg:justify-normal    hover:text-indigo-300   duration-300"
                  to="/banner">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="lucide lucide-picture-in-picture-2">
                    <path d="M21 9V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v10c0 1.1.9 2 2 2h4" />
                    <rect width="10" height="7" x="12" y="13" rx="2" />
                  </svg>

                  <p class="hidden" :class="isOpenSidebar ? 'hidden lg:flex' : 'hidden'">ផ្ទាំងបង្ហាញ
                  </p>
                </router-link>
              </div>

              <div>
                <router-link
                  :class="isOpenSidebar ? 'lg:pl-2' : 'xl:justify-center lg:justify-center md:justify-center'"
                  class="text-body flex space-x-2 text-nowrap  text-primery1 font-semibold md:p-3 p-4  md:justify-center  py-[12px] lg:justify-normal    hover:text-indigo-300   duration-300"
                  to="/hardwareCategory">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="size-[24px]">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25" />
                  </svg>

                  <p class="hidden" :class="isOpenSidebar ? 'hidden lg:flex' : 'hidden'">ប្រភេទឧបករណ៍
                  </p>
                </router-link>
              </div>


              <!-- <div>
                <router-link
                  :class="isOpenSidebar ? 'lg:pl-2' : 'xl:justify-center lg:justify-center md:justify-center'"
                  class="text-body flex space-x-2 text-nowrap  text-primery1 font-semibold md:p-3 p-4 md:justify-center  py-[12px] lg:justify-normal    hover:text-indigo-300   duration-300"
                  to="/bussiness">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="h-[24px] w-[24px]">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z" />
                  </svg>
                  <p class="hidden" :class="isOpenSidebar ? 'hidden lg:flex' : 'hidden transition-all '">
                    សេវាកម្មអាជីវកម្ម</p>
                </router-link>
              </div> -->


              <div>
                <router-link
                  :class="isOpenSidebar ? 'lg:pl-2' : 'xl:justify-center lg:justify-center md:justify-center'"
                  class="text-body flex space-x-2 text-nowrap  text-primery1 font-semibold md:p-3 p-4  md:justify-center  py-[12px] lg:justify-normal    hover:text-indigo-300   duration-300"
                  to="/products">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="lucide lucide-archive">
                    <rect width="20" height="5" x="2" y="3" rx="1" />
                    <path d="M4 8v11a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8" />
                    <path d="M10 12h4" />
                  </svg>
                  <p class="hidden" :class="isOpenSidebar ? 'hidden lg:flex' : 'hidden'">ផលិតផល</p>
                </router-link>
              </div>


              <div>
                <router-link
                  :class="isOpenSidebar ? 'lg:pl-2' : 'xl:justify-center lg:justify-center md:justify-center'"
                  class="text-body flex space-x-2 text-nowrap  text-primery1 font-semibold md:p-3 p-4 md:justify-center  py-[12px] lg:justify-normal    hover:text-indigo-300   duration-300"
                  to="/listproduct">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
                  </svg>

                  <p class="hidden" :class="isOpenSidebar ? 'hidden lg:flex' : 'hidden'">ឧបករណ៍
                  </p>
                </router-link>
              </div>

              <div v-if="userDocument.role == 'SuperAdmin'">
                <router-link
                  :class="isOpenSidebar ? 'lg:pl-2' : 'xl:justify-center lg:justify-center md:justify-center'"
                  class="text-body flex space-x-2 text-nowrap  text-primery1 font-semibold md:p-3 p-4 md:justify-center  py-[12px] lg:justify-normal    hover:text-indigo-300   duration-300"
                  to="/adminmanager">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="lucide lucide-users">
                    <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
                    <circle cx="9" cy="7" r="4" />
                    <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                  </svg>
                  <p class="hidden" :class="isOpenSidebar ? 'hidden lg:flex' : 'hidden'">
                    អ្នកប្រើប្រាស់</p>
                </router-link>
              </div>

              <div>
                <router-link
                  :class="isOpenSidebar ? 'lg:pl-2' : 'xl:justify-center lg:justify-center md:justify-center'"
                  class="text-body flex space-x-2 text-nowrap  text-primery1 font-semibold md:p-3 p-4 md:justify-center  py-[12px] lg:justify-normal    hover:text-indigo-300   duration-300"
                  to="/qanda">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="lucide lucide-circle-help">
                    <circle cx="12" cy="12" r="10" />
                    <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                    <path d="M12 17h.01" />
                  </svg>
                  <p class="hidden" :class="isOpenSidebar ? 'hidden lg:flex' : 'hidden'">សំណួរខ្លី</p>
                </router-link>
              </div>
              <div>
                <router-link
                  :class="isOpenSidebar ? 'lg:pl-2' : 'xl:justify-center lg:justify-center md:justify-center'"
                  class="text-body flex space-x-2 text-nowrap  text-primery1 font-semibold md:p-3 p-4 md:justify-center  py-[12px] lg:justify-normal    hover:text-indigo-300   duration-300"
                  to="/partner">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="lucide lucide-handshake">
                    <path d="m11 17 2 2a1 1 0 1 0 3-3" />
                    <path
                      d="m14 14 2.5 2.5a1 1 0 1 0 3-3l-3.88-3.88a3 3 0 0 0-4.24 0l-.88.88a1 1 0 1 1-3-3l2.81-2.81a5.79 5.79 0 0 1 7.06-.87l.47.28a2 2 0 0 0 1.42.25L21 4" />
                    <path d="m21 3 1 11h-2" />
                    <path d="M3 3 2 14l6.5 6.5a1 1 0 1 0 3-3" />
                    <path d="M3 4h8" />
                  </svg>
                  <p class="hidden" :class="isOpenSidebar ? 'hidden lg:flex' : 'hidden'">ដៃគូរសហការណ៍
                  </p>
                </router-link>
              </div>
              <div>
                <router-link
                  :class="isOpenSidebar ? 'lg:pl-2' : 'xl:justify-center lg:justify-center md:justify-center'"
                  class="text-body flex space-x-2 text-nowrap  text-primery1 font-semibold md:p-3 p-4 md:justify-center  py-[12px] lg:justify-normal    hover:text-indigo-300   duration-300"
                  to="/inbox">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="lucide lucide-package-open">
                    <path d="M12 22v-9" />
                    <path
                      d="M15.17 2.21a1.67 1.67 0 0 1 1.63 0L21 4.57a1.93 1.93 0 0 1 0 3.36L8.82 14.79a1.655 1.655 0 0 1-1.64 0L3 12.43a1.93 1.93 0 0 1 0-3.36z" />
                    <path
                      d="M20 13v3.87a2.06 2.06 0 0 1-1.11 1.83l-6 3.08a1.93 1.93 0 0 1-1.78 0l-6-3.08A2.06 2.06 0 0 1 4 16.87V13" />
                    <path
                      d="M21 12.43a1.93 1.93 0 0 0 0-3.36L8.83 2.2a1.64 1.64 0 0 0-1.63 0L3 4.57a1.93 1.93 0 0 0 0 3.36l12.18 6.86a1.636 1.636 0 0 0 1.63 0z" />
                  </svg>
                  <p class="hidden" :class="isOpenSidebar ? 'hidden lg:flex' : 'hidden'">ប្រអប់សារ</p>
                </router-link>
              </div>
            </div>

          </div>
        </div>



        <div class="mx-4 my-4 w-[100%] md:w-[100%] lg:w-[130%] ">
          <div>
            <NavbarInboxVue />
          </div>

          <div
            class="bg-white/50 my-5 w-full md:w-full lg:w-[100%] pb-7 lg:mx-auto xl:w-[70%] h-[750px] md:h-auto  lg:h-auto  xl:mx-auto xl:h-auto overflow-hidden px-7  overflow-y-auto"
            v-if="product">
            <div v-if="isAnswerOpen" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div v-motion-slide-top class="bg-white p-6 rounded-lg">
                <h2 class="text-lg font-semibold mb-4">សូមបញ្ជាក់ការឆ្លើយតប៖</h2>
                <div class="w-full flex items-center justify-center gap-4">
                  <button @click="cancelAnswer" class="px-4 py-2 mr-2 bg-gray-300 text-gray-800 rounded-lg">
                    បោះបង់
                  </button>
                  <button type="submit" @click="updateStatus('Answered')"
                    class="px-4 py-2 bg-green-500 text-white rounded-lg">
                    យល់ព្រម
                  </button>
                </div>
              </div>
            </div>
            <div v-if="showModal" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div v-motion-slide-top class="bg-white p-6 rounded-lg">
                <h2 class="text-lg font-semibold mb-4">
                  សូមផ្តល់ហេតុផលសម្រាប់ការបដិសេធ៖
                </h2>
                <textarea required v-model="rejectionReason"
                  class="w-full h-32 p-2 border border-gray-300 rounded-lg"></textarea>
                <div class="flex justify-end mt-4">
                  <button @click="cancelRejection" class="px-4 py-2 mr-2 bg-gray-300 text-gray-800 rounded-lg">
                    បោះបង់
                  </button>
                  <button type="submit" :disabled="rejectionReason == ''" @click="confirmRejection" :class="{
                    'px-4 py-2 bg-red-500 text-white rounded-lg':
                      rejectionReason != '',
                    'px-4 py-2 bg-red-500 text-white rounded-lg cursor-not-allowed':
                      rejectionReason == '',
                  }">
                    បញ្ចូន
                  </button>
                </div>
              </div>
            </div>


            <div class="space-y-5">
              <!-- <div>
              <h2 class="text-2xl font-bold">មើលប្រអប់សារ</h2>
            </div>
            <hr> -->


              <fieldset class="border-[1px] border-primery1 p-3 mt-5">
                <legend class="text-2xl">មើលប្រអប់សារ</legend>
                <div class="px-4 py-4 space-y-5">

                  <div class="flex items-center w-full ">
                    <div class="w-1/2">
                      <h2 class="text-xl text-primery1 font-semibold">ឈ្មោះ​ ៖</h2>
                    </div>

                    <div class="w-1/2 flex items-center justify-end">
                      <h2 class="text-lg text-textbody font-bold capitalize">{{ product.name }}</h2>
                    </div>
                  </div>
                  <div class="flex items-center w-full">
                    <div class="w-1/2">
                      <h2 class="text-xl text-primery1 font-semibold">
                        ឈ្មោះក្រុមហ៊ុន៖
                      </h2>
                    </div>

                    <div class="w-1/2 flex items-center justify-end">
                      <h2 class="text-lg text-textbody font-bold capitalize">{{ product.companyname }}</h2>
                    </div>
                  </div>
                  <div class="flex items-center w-full">
                    <div class="w-1/2">
                      <h2 class="text-xl text-primery1 font-semibold">សេវ៉ាកម្ម៖</h2>
                    </div>
                    <div class="w-1/2 flex items-center justify-end">
                      <h2 class="text-lg text-textbody font-bold capitalize">{{ product.itemsType }}</h2>
                    </div>
                  </div>
                  <div class="flex items-center w-full">
                    <div class="w-1/2">
                      <h2 class="text-xl text-primery1 font-semibold">
                        លេខតេលេក្រាម
                      </h2>
                    </div>

                    <div class="w-1/2 flex items-center justify-end">
                      <h2 v-if="product.numbertelete" class="text-xl text-textbody font-bold capitalize">
                        {{ product.numbertelete }}
                      </h2>
                      <h2 v-else class="text-xl text-textbody">មិនបានបញ្ចូល</h2>
                    </div>
                  </div>
                  <div class="flex items-center w-full">
                    <div class="w-1/2">
                      <h2 class="text-xl text-primery1 font-semibold">លេខទូរសព្ទ៖</h2>
                    </div>
                    <div class="w-1/2 flex items-center justify-end">
                      <h2 class="text-lg text-textbody font-bold capitalize">{{ product.number }}</h2>
                    </div>
                  </div>
                  <div class="flex items-center w-full">
                    <div class="w-1/2">
                      <h2 class="text-xl text-primery1 font-semibold">អាស័យដ្ឋាន៖</h2>
                    </div>
                    <div class="w-1/2 flex items-center justify-end">
                      <h2 v-if="product.address" class="text-xl text-textbody capitaliz font-bold">
                        {{ product.address }}
                      </h2>
                      <h2 v-else class="text-xl text-textbody">មិនបានបញ្ចូល</h2>
                    </div>
                  </div>
                  <div class="flex items-center w-full">
                    <div class="w-1/2">
                      <h2 class="text-xl text-primery1 font-semibold">គោលបំណង៖</h2>
                    </div>
                    <div class="w-1/2 flex items-center justify-end">
                      <h2 class="text-md text-textbody capitalize font-bold">{{ product.pospurse }}</h2>
                    </div>
                  </div>
                  <div class="flex items-center w-full">
                    <div class="w-1/2">
                      <h2 class="text-xl text-primery1 font-semibold">ស្ថានភាព៖</h2>
                    </div>
                    <div class="w-1/2 flex items-center justify-end space-x-2 font-bold">
                      <h2 :class="{
                        'text-md bg-yellow-500 text-white p-1 px-2 rounded-full': product.status == 'Pending',
                        'text-md bg-red-500 text-white p-1 px-2 rounded-full': product.status == 'Rejected',
                        'text-md bg-green-500 text-white p-1 px-2 rounded-full': product.status == 'Answered',
                      }">
                        <p v-if="product.status == 'Pending'">កំពុងរង់ចាំ</p>
                        <p v-if="product.status == 'Rejected'">បដិសេធ</p>
                        <p v-if="product.status == 'Answered'">បានឆ្លើយតប</p>
                      </h2>

                    </div>
                  </div>
                  <div class="flex justify-end gap-2">
                    <button v-if="product.status == 'Pending'" @click="rejectRequest()"
                      class="px-4  bg-red-500 rounded-md flex items-center justify-center gap-2 hover:bg-red-600 active:bg-red-700 duration-300">
                      <font-awesome-icon :icon="['fas', 'xmark']" class="text-white" />
                      <h2 class="text-sm text-white">បដិសេធ</h2>
                    </button>
                    <button v-if="product.status == 'Pending'" @click="handleIsAnswer"
                      class=" bg-green-500 px-2 p-2 rounded-md flex items-center justify-center gap-2 hover:bg-green-600 active:bg-green-700 duration-300">
                      <font-awesome-icon :icon="['fas', 'check']" class="text-white" />
                      <p class="text-sm text-white">ឆ្លើយតប</p>
                    </button>
                  </div>
                  <div class="flex items-center w-full" v-if="product.rejectionReason !== undefined &&
                    product.rejectionReason !== ''
                  ">
                    <div class="w-1/2">
                      <h2 class="text-xl text-primery1 font-semibold">មូលហេតុ៖</h2>
                    </div>
                    <div class="w-1/2 flex items-center justify-end">
                      <h2 class="text-lg text-textbody font-bold">{{ product.rejectionReason }}</h2>
                    </div>

                  </div>
                </div>
              </fieldset>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>


  <Notivue v-slot="item">
    <Notification :item="item" />
  </Notivue>
  <!-- <Footer /> -->
</template>

<script>

Notivue, Notification;
import { ref, onMounted, onBeforeUnmount } from "vue";
import { projectFirestore } from "@/firebase/config";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import Navbar from "./Navbar.vue";
import { push, Notivue, Notification } from "notivue";


import Footer from "./Footer.vue";
import { getAuth } from 'firebase/auth';
import NavbarInboxVue from '@/titleAdmin/NavbarInbox.vue';
export default {
  components: {
    Navbar,
    Footer,
    Notivue,
    Notification,
    NavbarInboxVue
  },
  props: {
    id: String,
  },
  setup(props) {
    const product = ref(null);
    const isAnswerOpen = ref(false);
    const isOpenSidebar = ref(true)
    const userDocument = ref(null);
    const showModal = ref(false);
    const rejectionReason = ref("");

    onMounted(() => {
      fetchProduct();
      fetchUserData();
    });


    const fetchProduct = async () => {
      try {
        const productRef = doc(projectFirestore, "inbox", props.id);
        const docSnap = await getDoc(productRef);
        if (docSnap.exists()) {
          product.value = docSnap.data();
        } else {
          console.error(`Product with ID ${props.id} not found.`);
        }
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };
    const updateStatus = async (newStatus) => {
      try {
        const productRef = doc(projectFirestore, "inbox", props.id);
        await updateDoc(productRef, {
          status: newStatus,
        });
        push.success("បានឆ្លើយតប");
        console.log("Status updated successfully.");
        product.value.status = newStatus;
        cancelAnswer();
      } catch (error) {
        console.error("Error updating status:", error);
      }
    };
    const confirmRejection = async () => {
      try {
        const productRef = doc(projectFirestore, "inbox", props.id);
        await updateDoc(productRef, {
          status: "Rejected",
          rejectionReason: rejectionReason.value,
        });
        console.log("Request rejected successfully.");
        showModal.value = false;
        rejectionReason.value = "";
        push.error("បានបដិសេធ");
      } catch (error) {
        console.error("Error rejecting request:", error);
      }
    };
    const fetchUserData = async () => {
      try {
        const userlogin = getAuth().currentUser;
        if (userlogin) {
          // Check if user data is already fetched
          if (!userDocument.value) {
            const docRef = doc(projectFirestore, "users", userlogin.uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              // Store user data in a persistent state
              userDocument.value = docSnap.data();
            }
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error.message);
      }
    };

    function handleIsAnswer() {
      isAnswerOpen.value = true;
    };

    function cancelAnswer() {
      isAnswerOpen.value = false;
    };

    function rejectRequest() {
      showModal.value = true;
    };

    function cancelRejection() {
      showModal.value = false;
      rejectionReason.value = "";
    };



    return {
      product,
      updateStatus,
      showModal,
      rejectionReason,
      rejectRequest,
      cancelRejection,
      confirmRejection,
      handleIsAnswer,
      isAnswerOpen,
      cancelAnswer,
      userDocument,
      isOpenSidebar
    };
  },
};
</script>
<style scoped>
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #510192b9;
  border-radius: 10px;
  margin-left: 20px;
  transition: 3s;
  height: 20px;
}

::-webkit-scrollbar-thumb:hover {
  display: block;
  transition: 0.5s;
}
</style>
