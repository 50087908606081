<template>
    <div class="w-full   h-[60px]  bg-white/50  flex font-NotoSansKhmer">
        <div class="items-center justify-between w-[98%] flex mx-auto">
            <div class="flex items-center space-x-4">
                <div>
                    <!-- <img class="w-10 h-10" src="../assets/image/image 5.png" alt="" /> -->
                    <p class="text-indigo-500 font-bold ml-5 md:ml-4 lg:ml-0">សេវ៉ាកម្មអាជីវកម្ម</p>
                </div>
                <!-- <div class="">
            <h1 class="text-primery1 text-lg">Master IT System</h1>
          </div> -->
            </div>
            <div v-if="userDocument" class="flex items-center space-x-2">
                <div class="p-2 relative">
                    <button @click="handleGotoInbox"
                        class="w-4 h-4 bg-red-500 rounded-[100%] absolute right-0 top-1 flex items-center justify-center text-white text-[12px]">
                        {{ inbox.length }}
                    </button>
                    <font-awesome-icon :icon="['fas', 'bell']" class="text-primery1 w-4 h-4" />
                </div>
                <div @click="handleGotoProfile"
                    class="flex items-center justify-center  gap-3 p-2 rounded-full h-[60px] cursor-pointer">
                    <div class="py-1 rounded-full">
                        <p class="text-body overflow-hidden">
                            ប្រវត្តិរូប៖​ {{ userDocument.username }}
                        </p>
                    </div>
                    <div v-if="userDocument" class="w-10 h-10 bg-indigo-500 rounded-full">
                        <div v-if="!userDocument.photoURL" class="w-full h-full flex items-center justify-center">
                            <h2 class="text-white uppercase text-sm">
                                {{ userDocument.username[0]}}
                            </h2>
                        </div>
                        <img v-else class="object-cover w-full h-full  rounded-full" :src="userDocument.photoURL"
                            :alt="userDocument.photoURL" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import getUser from "../composible/getUser";
import { getCollectionQuery } from "@/composible/getCollection";
import { onMounted, ref } from "vue";
import { getAuth } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { projectFirestore } from "@/firebase/config";
import { useRouter } from "vue-router";
export default {
    setup() {
        const router = useRouter();
        const dataitem = ref([]);
        const inbox = ref([]);
        const userDocument = ref(null);
        const { user } = getUser();
        
        onMounted(() => {
            getData();
            getDataInbox();
            fetchUserData();
        });
      

        const getData = async () => {
            try {
                const data = await getCollectionQuery(
                    "users",
                    [],
                    (data) => {
                        dataitem.value = data;
                    },
                    true
                );
            } catch (error) {
                console.error(error.message);
            }
        };
       
        const getDataInbox = async () => {
            try {
                const data = await getCollectionQuery(
                    "inbox",
                    [],
                    (data) => {
                        inbox.value = data.filter((item) => item.status === "Pending");
                    },
                    true
                );
            } catch (error) {
                console.error(error.message);
            }
        };
        const handleGotoProfile = () => {
            router.push({ name: "profile" });
        };
        const handleGotoInbox = () => {
            router.push({ name: "inbox" });
        };
   

        const fetchUserData = async () => {
            try {
                const userlogin = getAuth().currentUser;
                if (userlogin) {
                    // Check if user data is already fetched
                    if (!userDocument.value) {
                        const docRef = doc(projectFirestore, "users", userlogin.uid);
                        const docSnap = await getDoc(docRef);
                        if (docSnap.exists()) {
                            // Store user data in a persistent state
                            userDocument.value = docSnap.data();
                        }
                    }
                }
            } catch (error) {
                console.error("Error fetching user data:", error.message);
            }
        };


        return {
            user,
            dataitem,
            userDocument,
            handleGotoProfile,
            inbox,
            handleGotoInbox,
        };
    },
};
</script>
<style scoped></style>