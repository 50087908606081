<template>
  <div>

  </div>
  <div v-motion :initial="{
    opacity: 0,
  }" :enter="{

    opacity: 1,
    transition: {
      duration: 500,

      ease: 'easeIn',
    },
  }" class="flex flex-col py-4">
    <div v-for="pro in product" :key="pro.id">
      <div class="w-[100%] px-2  md:w-[97%] xl:w-[1280px] lg:w-[90%] mx-auto md:space-y-0 lg:space-y-4 xl:px-4">
        <div class="lg:mt-[32px] xl:mt-[32px]  mt-0 text-start lg:p-0 xl:p-0 p-3 ">
          <h2
            class="font-NotoSansKhmer font-bold lg:ml-0 md:ml-3 text-primery1 xl:text-heading2 lg:text-heading2 md:text-[20px] text-[18px] xl:text-start lg:text-start md:text-start text-center">
            {{ pro.name }}
          </h2>
        </div>

      
        <div
          class="w-full  md:mx-0 lg:ml-0 max-w-[1420px] py-1 lg:p-0 xl:p-0 p-3 md:ml-3 xl:flex lg:flex md:flex block gap-8 space-y-2 xl:space-y-0 lg:space-y-0 md:space-y-0">
          <!-- <div class="xl:w-1/2 lg:w-1/2 md:w-1/2 block">
            <img :src="product.image" alt="" class="rounded-[6px]" />
          </div> -->
          <div class="w-full lg:w-full xl:w-[100%]  block mb-5">
            <span v-html="pro.descritpts"
              class="prose text-textbody text-justify  lg:-mt-0 xl:text-body lg:text-[16px] md:text-[16px] text-[14px]">

            </span>
          </div>
        </div>

     


        <div v-for="(productfeatures, index) in pro.feature" :key="index" class="w-full px-4 lg:px-0 ">
          <!-- details1 -->

          <div class="w-full mx-auto ">
            <div class="w-full">

              <div class="grid lg:grid-cols-2 grid-cols-1 md:grid-cols-1  gap-10 lg:gap-14 items-center">

                <div class="w-full">
                  <p class="text-lg font-NotoSansKhmer font-bold">រូបភាព</p>
                  <!-- <img
                    class="w-[400px] h-[250px] md:w-[900px]  md:h-[400px] lg:w-[500px] lg:h-[350px] xl:w-[600px] xl:h-[400px] rounded-[6px]"
                    :src="productfeatures.image" /> -->

                  <img
                    class="w-[400px] h-[250px] md:w-[900px]  md:h-[400px] lg:w-[500px] lg:h-[350px] xl:w-[600px] xl:h-[400px] rounded-[6px]"
                    src="https://fakeimg.pl/600x400/" />

                </div>
                <div class="text-textbody text-[14px] text-start font-[500] lg:mt-5 xl:mt-0">
                  <h2 class="text-primery1 -mt-5 font-NotoSansKhmer text-[20px]  font-semibold text-start mb-1">
                    {{ productfeatures.title }}
                  </h2>
                  <!-- <span class="prose"> {{ productfeatures.details }}</span> -->
                  <span
                    class="text-textbody text-[15px] md:text-[18px] lg:text-[14px] xl:text-[18px]  xl:mt-0 text-start font-[500] prose "
                    v-html="productfeatures.details"></span>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="lg:mt-[32px] xl:mt-[32px]  mt-0 text-start lg:p-0 xl:p-0 p-3 ">
          <p class="text-lg font-NotoSansKhmer font-bold text-center mt-14 mb-5">វីដេអូសង្ខេបនីមួយៗ</p>

          <!-- <video v-if="product.video" controls 
            class="  w-full  shadow-xl mt-2 md:w-full lg:w-full lg:h-[500px] border-[2px] border-blue-500 md:h-[400px] xl:w-full xl:h-[500px] bg-gradient-to-r from-[#500192] to-indigo-700/60">
            <source :src="product.video" class="border-2 " type="video/mp4">
          </video> -->

          <!-- <div class=" flex justify-start">
            <img
              class="w-[400px] h-[300px]  shadow-md rounded-md md:w-[500px] lg:w-[500px] lg:h-[300px] border-[2px] mx-auto  md:h-[300px] xl:w-[600px] xl:h-[400px]"
              src="https://fakeimg.pl/512x500/" alt="">
          </div> -->

          <div class="flex justify-start">
            <iframe v-if="product.videoLink && getYouTubeEmbedLink(product.videoLink)"
              :src="getYouTubeEmbedLink(product.videoLink)" frameborder="0" allow="autoplay; encrypted-media"
              allowfullscreen
              class="w-[400px] h-[300px]  shadow-md rounded-md md:w-[500px] lg:w-[500px] lg:h-[300px] border-[2px] mx-auto  md:h-[300px] xl:w-[600px] xl:h-[400px] aspect-video"></iframe>

            <video v-else-if="product.videoLink && /\.(mp4|webm|ogg|m4v)$/i.test(product.videoLink)"
              :src="product.videoLink" controls
              class="w-[400px] h-[300px]  shadow-md rounded-md md:w-[500px] lg:w-[500px] lg:h-[300px] border-[2px] mx-auto  md:h-[300px] xl:w-[600px] xl:h-[400px]"></video>


            <img v-else
              class="w-[400px] h-[300px]  shadow-md rounded-md md:w-[500px] lg:w-[500px] lg:h-[300px] border-[2px] mx-auto  md:h-[300px] xl:w-[600px] xl:h-[400px]"
              src="https://fakeimg.pl/512x500/" alt="">

          </div>

        </div>



        <!-- mobile  -->
        <div v-for="(productfeatures, index) in pro.feature" :key="index"
          class="w-full hidden lg:hidden md:hidden  xl:hidden p-4">
          <!-- details1 -->

          <div class="w-full mx-auto">
            <div class="w-full">
              <h2 class="text-primery1 -mt-5 font-NotoSansKhmer text-[18px]  font-semibold text-center">
                {{ productfeatures.title }}
              </h2>
              <div>
                <div class="text-textbody text-[14px] text-start font-[500]">
                  <!-- <span class="prose"> {{ productfeatures.details }}</span> -->
                  <span class="text-textbody text-[14px] text-start font-[500] prose"
                    v-html="productfeatures.details"></span>
                </div>
              </div>
            </div>
            <div class="w-full mt-8">
              <img class="w-full rounded-[6px]" :src="productfeatures.image" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full h-[48px]"></div>
  </div>


  <div class="w-full max-w-full  h-auto py-12 flex flex-col items-center justify-center bg-gray-50/20">
    <div class="lg:w-[1024px] xl:w-full md:w-full w-full h-auto">
      <div class=" lg:w-[1024px] xl:w-[1280px] w-full md:w-full mx-auto xl:px-4">
        <div class="lg=:-mt-[1rem] md:-mt-[2rem] text-center">
          <h2
            class="font-NotoSansKhmer font-bold -mt-4 text-primery1 md:mt-3  text-heading4 md:text-[26px]  lg:text-Heading1 xl:text-[24px]">
            អ្នកអាចសួរសំណួរនៅទីនេះ
          </h2>
        </div>
        <form @submit.prevent="handleSubmit"
          class="w-[90%]  xl:w-[100%] lg:w-[95%] md:w-[90%] mx-auto mt-5 lg:mt-10 space-y-2">
          <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:gap-8 lg:gap-5">

            <div class="space-y-2">
              <label required for="name" class="text-body font-[500]">ឈ្មោះ<span class="text-red-500"> *</span></label>
              <input required class="input1" name="name" type="text" placeholder="ឈ្មោះរបស់អ្នក" v-model="name" />
            </div>
            <div class="space-y-2 mt-2 lg:mt-0">
              <label for="name" class="text-body font-[500]">ឈ្មោះក្រុមហ៊ុន <span class="text-red-500">*</span></label>
              <input required class="input1" name="name" type="text" placeholder="ឈ្មោះក្រុមហ៊ុន"
                v-model="companyname" />
            </div>

          </div>

          <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:gap-8 lg:gap-5">
            <div class="space-y-2">
              <label for="name" class="text-body font-[500]">លេខទូរស័ព្ទ <span class="text-red-500">*</span></label>
              <input required class="input1" name="name" type="text" placeholder="លេខទូរស័ព្ទ" v-model="number" />
            </div>
            <div class="space-y-2">
              <label for="name" class="text-body font-[500]">តេលេក្រាម</label>
              <input class="input1" name="name" type="text" placeholder="តេលេក្រាម" v-model="numbertelete" />
            </div>
          </div>
          <div class="space-y-2">
            <label for="name" class="text-body font-[500]">អាស័យដ្ឋាន</label>
            <input class="input1" name="name" type="text" placeholder="អាស័យដ្ឋាន" v-model="address" />
            <input required class="input hidden" name="name" type="text" placeholder="អាស័យដ្ឋាន" v-model="status" />
            <div></div>
          </div>
          <label for="name" class="text-body font-[500]">សេវាកម្ម <span class="text-red-500">*</span></label>
          <div class="">
            <select required class="input1 w-full text-[18px] " v-model="itemsType">

              <option disabled selected value class="placeholder-option">--ជ្រើសរើសសេវាកម្ម--</option>
              <!-- Loop through the data array for other options -->
              <option v-for="option in category" :key="option.id" :value="option.name" class="option-item">
                {{ option.name }}
              </option>
            </select>
          </div>
          <div class="space-y-2">
            <label for="គោលបំណង" class="text-body font-[500]">គោលបំណង <span class="text-red-500">*</span></label>
            <textarea required class="input1" placeholder="គោលបំណង" name="" id="" cols="30" rows="5"
              v-model="pospurse"></textarea>
          </div>
          <div class="flex w-full">
            
            <button
              class="btn-hover rounded-md text-[17px] md:mt-[5px]  md:text-[14px] lg:text-[16px] xl:text-[18px] font-NotoSansKhmer font-bold grow_skew_forward relative z-10 text-white text-2xl py-2 px-12 mt-4  hover:border-blue-500 overflow-hidden bg-gradient-to-r from-purple-600 to-indigo-700 shadow-xl">
              បញ្ជូន
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <Notivue v-slot="item">
    <Notification :item="item" />
  </Notivue>
</template>

<script>
import { push, Notivue, Notification } from "notivue";

import FooterVue from "./Footer.vue";
import NavbarVue from "./Navbar.vue";
import { ref, onMounted, onBeforeUnmount, watch } from "vue";
import { projectFirestore } from "@/firebase/config";
import { doc, documentId, getDoc, where } from "firebase/firestore";
import useCollection from "@/composible/useCollection";
import { getCollectionQuery } from "@/composible/getCollection";
import { timestamp } from "@/firebase/config";
import { useRoute } from 'vue-router';
import Navbar from "./Navbar.vue";
import getCollection from "@/composible/getCollections";
export default {
  props: {
    id: String,
  },
  components: {
    FooterVue,
    NavbarVue,
    Notivue,
    Notification,
    Navbar
  },
  setup(props) {
    const product = ref(null);

    const route = useRoute();

    const fetchProduct = async () => {
      try {
        const { documents } = getCollection('products', where(documentId(), "==", route.params.id));

        watch(documents, () => {
          product.value = documents.value;
        })

      } catch (error) {

        console.error("Error fetching product:", error);
      }
    };

    onMounted(() => {
      fetchProduct();
    });
    const status = ref("Pending");
    const category = ref([]);
    const itemsType = ref("");
    const name = ref("");
    const companyname = ref("");
    const number = ref("");
    const numbertelete = ref("");
    const address = ref("");
    const pospurse = ref("");

    const { addDocs } = useCollection("inbox");
    const handleSubmit = async () => {
      try {
        const productData = {
          status: status.value,
          name: name.value,
          companyname: companyname.value,
          numbertelete: numbertelete.value,
          itemsType: itemsType.value,
          pospurse: pospurse.value,
          createdAt: timestamp(),
        };

        // Add 'number' field if it has a value
        if (number.value.trim() !== "") {
          productData.number = number.value;
        }

        // Add 'address' field if it has a value
        if (address.value.trim() !== "") {
          productData.address = address.value;
        }

        await addDocs(productData);
        handleClear();
        push.success("អរគុណសម្រាប់ការទាក់ទង");

        console.log("Product operation successful");
      } catch (error) {
        console.error("Error performing product operation:", error);
      }
    };

    const getDataCategory = async () => {
      try {
        await getCollectionQuery(
          "categories",
          [],
          (data) => {
            category.value = data;
          },
          true
        );
      } catch (error) {
        console.error(error.message);
      }
    };
    onMounted(() => {
      getDataCategory();
    });
    const handleClear = () => {
      status.value = "";
      name.value = "";
      companyname.value = "";
      number.value = "";
      numbertelete.value = "";
      address.value = "";
      pospurse.value = "";
      itemsType.value = "";
    };


    
    const getYouTubeEmbedLink = (url) => {
      const youtubeRegex = /(?:youtu\.be\/|youtube\.com\/(?:[^\/]+\/\S+\/|(?:watch\?v=)))([a-zA-Z0-9_-]+)/;
      const match = url.match(youtubeRegex);
      if (match) {
        return `https://www.youtube.com/embed/${match[1]}`;
      }
      return '';
    };




    return {
      product,
      itemsType,
      category,
      name,
      companyname,
      number,
      numbertelete,
      address,
      pospurse,
      status,
      handleSubmit,
      getYouTubeEmbedLink
    };
  },
};
</script>
<style scoped>
::-webkit-scrollbar {
  display: none;
}

p {
  line-height: 2;
}
</style>
