<template>

  <div class="">
    <div v-if="isLogout == true"
      class="fixed top-0 bg-black/35 w-full h-screen z-[30] flex items-center justify-center">
      <div v-motion-pop-visible class="w-[350px] bg-background font-NotoSansKhmer shadow-md p-3 space-y-4">
        <h2 class="text-heading4 font-semibold text-primery1">
          តើអ្នកពិតជាចង់ចាកចេញមែនឬ ?
        </h2>
        <div class="flex items-center justify-end gap-3">
          <button class="bg-accent hover:bg-amber-500 transition-all ease-in-out mt-2 px-4 py-2 duration-200"
            @click="handleLogout">
            បោះបង់
          </button>
          <button @click="handleSignOut"
            class=" bg-red-500 text-white hover:bg-red-600 transition-all ease-in-out mt-2 px-4 py-2 duration-200">
            យល់ព្រម
          </button>
        </div>
      </div>
    </div>

    <div class="w-full">
      <NavbarVue />
    </div>


    <div class="flex justify-center  items-center sm:h-screen pb-3 md:h-screen bg-gray-100/90">
      <div class="w-[90%] mt-[8rem]  lg:w-[60%] xl:w-[50%] mx-auto font-NotoSansKhmer relative">
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10">
          <div>
            <router-link to="/services" class="">
              <button
                class=" bg-white w-full h-44 rounded-xl flex justify-center items-center hover:bg-pink-600 transition-all duration-300 hover:text-white">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                    class="ml-10 bg-pink-500  rounded-full p-2 h-[48px] text-white shadow-xl">
                    <path d="M16.5 6a3 3 0 0 0-3-3H6a3 3 0 0 0-3 3v7.5a3 3 0 0 0 3 3v-6A4.5 4.5 0 0 1 10.5 6h6Z" />
                    <path d="M18 7.5a3 3 0 0 1 3 3V18a3 3 0 0 1-3 3h-7.5a3 3 0 0 1-3-3v-7.5a3 3 0 0 1 3-3H18Z" />
                  </svg>
                  <h2 class="text-2xl font-bold mt-3 ">ប្រភេទសេវាកម្ម</h2>
                </div>
              </button>
            </router-link>
          </div>

          <div>
            <router-link to="/hardwareCategory" class="">
              <button
                class=" bg-white w-full h-44 rounded-xl flex justify-center items-center hover:bg-orange-600 transition-all duration-300 hover:text-white">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="ml-10 bg-orange-400  rounded-full p-2 h-[48px] text-white shadow-xl">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25" />
                  </svg>
                  <h2 class="text-2xl font-bold mt-3 ">ប្រភេទឧបករណ៍</h2>
                </div>
              </button>
            </router-link>
          </div>
          <div>
            <router-link to="/banner" class="">
              <button
                class=" bg-white w-full h-44 rounded-xl flex justify-center items-center hover:bg-primery2 transition-all duration-300 hover:text-white">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="ml-5 bg-primery1  rounded-full p-2 h-[48px] text-white shadow-xl">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z" />
                  </svg>

                  <h2 class="text-2xl font-bold mt-3 ">ផ្ទាំបង្ហាញ</h2>
                </div>
              </button>
            </router-link>
          </div>
          <div>
            <router-link to="/products" class="">
              <button
                class=" bg-white w-full h-44 rounded-xl flex justify-center items-center hover:bg-green-500 transition-all duration-300 hover:text-white">
                <div>

                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="ml-5 bg-green-400  rounded-full p-2 h-[48px] text-white shadow-xl">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                  </svg>

                  <h2 class="text-2xl font-bold mt-3 ">ផលិតផល</h2>
                </div>
              </button>
            </router-link>
          </div>

          <div>
            <router-link to="/listproduct" class="">
              <button
                class=" bg-white w-full h-44 rounded-xl flex justify-center items-center hover:bg-indigo-800 transition-all duration-300 hover:text-white">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor"
                    class="ml-[3rem] bg-indigo-700  rounded-full p-2 h-[48px] text-white shadow-xl">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
                  </svg>

                  <h2 class="text-2xl font-bold mt-2 ">បញ្ជីឧបករណ៍ថ្មី</h2>
                </div>
              </button>
            </router-link>
          </div>

          <div>

            <router-link to="/qanda" class="">
              <button
                class=" bg-white w-full h-44 rounded-xl flex justify-center items-center hover:bg-red-600 transition-all duration-300 hover:text-white">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="ml-4 bg-red-500  rounded-full p-2 h-[48px] text-white shadow-xl">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
                  </svg>

                  <h2 class="text-2xl font-bold mt-3 ">សំណួរខ្លី</h2>
                </div>
              </button>
            </router-link>
          </div>
          <div>
            <router-link to="/partner" class="">
              <button
                class=" bg-white w-full h-44 rounded-xl flex justify-center items-center hover:bg-blue-600 transition-all duration-300 hover:text-white">
                <div>

                  <font-awesome-icon class="ml-0 bg-blue-500  rounded-full h-9 w-9 p-2  text-white shadow-xl"
                    icon="handshake-simple" />
                  <h2 class="text-2xl font-bold mt-2 ">ដៃគូសហការណ៍</h2>
                </div>
              </button>
            </router-link>

          </div>
          <div>

            <router-link to="/inbox" class="">
              <button
                class=" bg-white w-full h-44 rounded-xl flex justify-center items-center hover:bg-orange-600 transition-all duration-300 hover:text-white">
                <div>

                  <font-awesome-icon :icon="['fas', 'inbox']"
                    class="ml-0 bg-orange-500  rounded-full h-7 w-7 p-3  text-white shadow-xl" />

                  <h2 class="text-2xl font-bold mt-2 ">ប្រអប់សារ</h2>
                </div>
              </button>
            </router-link>
          </div>



          <div v-if="userDocument">
            <div v-if="userDocument.role == 'SuperAdmin'">
              <router-link to="/adminmanager" class="">
                <button
                  class=" bg-white w-full h-44 rounded-xl flex justify-center items-center hover:bg-blue-700 transition-all duration-300 hover:text-white">
                  <div>

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                      class="ml-[30px] bg-blue-900  rounded-full p-2 h-[48px] text-white shadow-xl">
                      <path
                        d="M4.5 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM14.25 8.625a3.375 3.375 0 1 1 6.75 0 3.375 3.375 0 0 1-6.75 0ZM1.5 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM17.25 19.128l-.001.144a2.25 2.25 0 0 1-.233.96 10.088 10.088 0 0 0 5.06-1.01.75.75 0 0 0 .42-.643 4.875 4.875 0 0 0-6.957-4.611 8.586 8.586 0 0 1 1.71 5.157v.003Z" />
                    </svg>

                    <h2 class="text-2xl font-bold mt-2 ">អ្នកប្រើប្រាស់</h2>
                  </div>
                </button>
              </router-link>
            </div>
          </div>



        </div>
      </div>
      <button @click="handleLogout" class="bottom-5 right-5  fixed  ">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="size-6 bg-red-600 h-12 w-12 p-3 rounded-full text-white shadow-xl hover:bg-red-500 transition-all ease-in-out duration-300">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15" />
        </svg>

      </button>
    </div>

  </div>
  <!-- <FooterVue /> -->
</template>

<script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import NavbarVue from "./Navbar.vue";
import FooterVue from "../admin/Footer.vue";
import { projectAuth } from "@/firebase/config";
import { getAuth } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { projectFirestore } from "@/firebase/config";

export default {
  components: { NavbarVue, FooterVue },
  setup() {
    const router = useRouter();
    const handleGotoService = () => {
      router.push({ name: "services" });
    };
    const handleSignOut = async () => {
      try {
        await projectAuth.signOut();
        console.log("Sign-out successful");
        router.push({ name: "login" });
      } catch (error) {
        console.error("Error signing out:", error.message);
      }
    };
    const isLogout = ref(false);
    const handleLogout = () => {
      isLogout.value = !isLogout.value;
    };
    const userDocument = ref(null);
    const fetchUserData = async () => {
      try {
        const userlogin = getAuth().currentUser;
        if (userlogin) {
          const docRef = doc(projectFirestore, "users", userlogin.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            userDocument.value = docSnap.data();
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error.message);
      }
    };
    onMounted(() => {
      fetchUserData();
    });
    return {
      handleGotoService,
      handleSignOut,
      isLogout,
      handleLogout,
      userDocument,
    };
  },
};
</script>
