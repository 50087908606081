<template>
    <div>

    </div>
    <div v-motion :initial="{
        opacity: 0,
    }" :enter="{

        opacity: 1,
        transition: {
            duration: 500,
            ease: 'easeIn',
        },
    }" class="flex flex-col bg-background xl:pt-4 mb-10 md:mb-20 lg:mb-10">
        <div class="xl:w-full md:w-full w-full h-auto xl:py-3 lg:mb-[0px] xl:mb-0">
            <div class="lg:w-full xl:w-[1200px] w-full md:w-full mx-auto">
                <div class="lg:mt-0 xl:-mt-8  text-center">
                    <div class="lg:mt-5 xl:mt-0  text-center pb-5">
                        <h1
                            class="text-center text-primery1 xl:text-3xl xl:my-0 md:text-3xl md:mt-[25px] mt-5 lg:text-2xl  text-2xl xl:mt-10 font-NotoSansKhmer">
                            សកម្មភាពក្រុមការងារ
                        </h1>
                        <div class="border-b-[4px] border-primery1 w-16 lg:w-16 xl:w-20 mx-auto mt-1.5"></div>
                    </div>
                </div>
            </div>
            <!-- 

            <div class="sm:mb-40 md:mb-10 lg:mb-0">
                <div class="lg:w-[85%] xl:w-[1000px] md:w-[90%] md:px-4 mx-auto xl:px-5 mt-5 xl:my-10 px-10">
                    <div class="w-full relative   sm:relative md:grid-cols-2 grid">
                        <div class="hidden sm:block">
                            <div
                                class=" grid grid-cols-2 items-center ml-1 p-10 shadow-lg bg-gradient-to-r from-[#4a38b1] to-indigo-800/40 text-white w-full md:h-[250px] lg:h-[300px] md:w-[500px] lg:w-[700px] rounded-2xl">
                                <h2 class="md:text-[25px] lg:text-[27px] xl:text-[35px] font-bold">
                                    សកម្មភាពក្រុមការងារបង្រៀនអតិថិជនប្រើប្រាស់ស៊ីស្ទឹម។</h2>
                            </div>
                        </div>
                        <div
                            class="bg-gray-300 rounded-b-lg  top-20 sm:rounded-2xl shadow-md -right-5 lg:right-10 sm:absolute sm:top-8 border-[2px] ">
                            <img class="w-full h-[300px]  rounded-b-lg  sm:w-[300px] sm:h-[250px] md:w-[350px] md:h-[300px] lg:w-[400px] lg:h-[350px] xl:w-[450px] xl:h-[400px] sm:rounded-2xl object-cover"
                                src="https://fakeimg.pl/450x400/"
                                alt="">
                        </div>
                        <div class="block sm:hidden">
                            <div
                                class="rounded-b-lg items-center absolute flex p-3 bottom-0 text-justify border-[2px]  shadow-lg bg-gradient-to-r from-[#4a38b1] to-indigo-800/40 text-white w-full">
                                <h2 class="text-[15px] md:text-[25px] lg:text-[27px] xl:text-[35px] font-bold">
                                    សកម្មភាពក្រុមការងារបង្រៀនអតិថិជនប្រើប្រាស់ស៊ីស្ទឹម។</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->


            <div
                class=" xl:mt-10 lg:w-[85%] xl:w-[1000px] md:w-[90%] md:px-4 mx-auto xl:px-5 mt-5 space-y-4 sm:space-y-14  px-10 md:space-y-14">


                <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 md:grid-cols-2 md:gap-10 ">

                    <div class="sm:mt-8 md:mt-10 lg:mt-20">

                        <div class="bg-white border p-4 md:p-5 shadow-sm">
                            <h2 class="text-[18px] md:text-[20px] lg:text-[27px] xl:text-[28px] font-bold">
                                សកម្មភាពក្រុមការងារបង្រៀនអតិថិជនប្រើប្រាស់ស៊ីស្ទឹម។</h2>
                        </div>

                    </div>
                    <div class="">
                        <img class="w-full border shadow-sm h-[250px]   sm:w-[300px] sm:h-[250px] md:w-[350px] md:h-[300px] lg:w-[400px] lg:h-[350px] xl:w-[450px] xl:h-[400px]  object-cover"
                            src="https://fakeimg.pl/450x400/"
                            alt="">
                    </div>

                </div>


                <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 md:grid-cols-2 md:gap-10  ">


                    <div class="">
                        <img class="w-full border h-[250px] shadow-sm   sm:w-[300px] sm:h-[250px] md:w-[350px] md:h-[300px] lg:w-[400px] lg:h-[350px] xl:w-[450px] xl:h-[400px]  object-cover"
                            src="https://fakeimg.pl/450x400/"
                            alt="">
                    </div>


                    <div class="sm:mt-8 md:mt-10 lg:mt-16">

                        <div class="bg-white border p-4 md:p-5 shadow-sm">
                            <h2 class="text-[18px] md:text-[20px] lg:text-[27px] xl:text-[28px] font-bold">
                                សកម្មភាពរៀបចំដំឡើងប្រព័ន្ធគ្រប់គ្រងអាជីវកម្មជូនអតិថិជន។</h2>
                        </div>

                    </div>

                </div>


                <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 md:grid-cols-2 md:gap-10 ">

                    <div class="sm:mt-8 md:mt-10 lg:mt-20">

                        <div class="bg-white border p-4 md:p-5 shadow-sm">
                            <h2 class="text-[18px] md:text-[20px] lg:text-[27px] xl:text-[28px] font-bold">
                                សកម្មភាពក្រុមការងារកំពុងអភិវឌ្ឍន៍កម្មវិធី​ និង ប្រព័ន្ធគ្រប់គ្រងអាជីវកម្ម។</h2>
                        </div>

                    </div>
                    <div class="">
                        <img class="w-full border shadow-sm h-[250px]   sm:w-[300px] sm:h-[250px] md:w-[350px] md:h-[300px] lg:w-[400px] lg:h-[350px] xl:w-[450px] xl:h-[400px]  object-cover"
                            src="https://fakeimg.pl/450x400/"
                            alt="">
                    </div>
                </div>


                <h1
                    class="text-center text-primery1 xl:text-3xl xl:my-0 md:text-3xl md:mt-[25px] mt-5 lg:text-2xl  text-2xl xl:mt-10 font-NotoSansKhmer">
                    សកម្មភាពផ្សេងៗ
                </h1>
                <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 md:grid-cols-2 md:gap-10 ">

                    <div class="">

                        <div class="bg-white">
                            <img class="w-full border shadow-sm h-[250px]   sm:w-[300px] sm:h-[250px] md:w-[350px] md:h-[300px] lg:w-[400px] lg:h-[350px] xl:w-[450px] xl:h-[400px]  object-cover"
                                src="https://fakeimg.pl/450x400/"
                                alt="">
                        </div>

                    </div>
                    <div class="">
                        <img class="w-full border shadow-sm h-[250px]   sm:w-[300px] sm:h-[250px] md:w-[350px] md:h-[300px] lg:w-[400px] lg:h-[350px] xl:w-[450px] xl:h-[400px]  object-cover"
                            src="https://fakeimg.pl/450x400/"
                            alt="">
                    </div>

                    <div class="">

                        <div class="bg-white">
                            <img class="w-full border shadow-sm h-[250px]   sm:w-[300px] sm:h-[250px] md:w-[350px] md:h-[300px] lg:w-[400px] lg:h-[350px] xl:w-[450px] xl:h-[400px]  object-cover"
                                src="https://fakeimg.pl/450x400/"
                                alt="">
                        </div>

                    </div>
                    <div class="">
                        <img class="w-full border shadow-sm h-[250px]   sm:w-[300px] sm:h-[250px] md:w-[350px] md:h-[300px] lg:w-[400px] lg:h-[350px] xl:w-[450px] xl:h-[400px]  object-cover"
                            src="https://fakeimg.pl/450x400/"
                            alt="">
                    </div>

                </div>
            </div>
        </div>
    </div>


</template>

<script>
import { useRouter } from "vue-router";
import getCollection from "@/composible/getCollections.js";


import Navbar from "./Navbar.vue";
export default {
    components: {
        Navbar
    },
    setup() {


        const { documents: category } = getCollection('categories')


        const router = useRouter();

        return { category };
    },
};
</script>

<style scoped></style>