<template>
    <div
        class="mx-auto lg:w-[90%] xl:w-[1280px] md:w-full w-full   p-4 mb-10 md:mb-[30px] lg:mb-[20px] xl:mb-[60px] lg:space-y-8 xl:space-y-10">
        <div class="lg:space-y-[5px] xl:space-y-[10px] text-center hidden lg:block">

            <div class=" xl:mt-0  text-center">
                <h1
                    class="text-center text-primery1 xl:text-3xl xl:my-0 md:text-3xl mt-5 lg:text-2xl  text-2xl  font-NotoSansKhmer">
                    សំណួរដែលគេសួរញឹកញាប់
                </h1>
                <div class="border-b-[4px] border-primery1 w-16 lg:w-20 xl:w-24 mx-auto mt-2"></div>

            </div>
            <div>
                <p
                class="px-10 text-justify text-textbody/70 md:p-0 md:text-center font-[400] md:text-[20px] lg:text-body  xl:text-[20px]   font-NotoSansKhmer lg:w-[95%] mx-auto xl:w-[100%] w-full">
                សំណួរដែលអតិថិជនពេញនិយមសួរអំពីផលិតផល និងសេវាកម្មពួកយើង។
              </p>
               
            </div>
        </div>
        <div class="hidden md:justify-center lg:gap-10 xl:gap-[50px] lg:flex xl:flex   items-start justify-between ">

            <div
                class=" grid grid-cols-1 gap-y-4 font-NotoSansKhmer  lg:w-[90%] lg:text-body xl:text-body text-textbody lg:h-auto xl:w-full xl:h-auto md:w-full md:h-auto w-full rounded-[6px] duration-300 text-body">

                <div class="grid grid-cols-2 gap-4">
                    <div class="relative mb-3" v-for="(doc, index) in documents" :key="index">
                        <div class="border-[2px] rounded-md lg:p-3 ">
                            <h6 class="mb-0 md:pr-2 lg:pr-5 xl:pr-8">
                                <button @click="toggle(index)" :aria-expanded="isOpen(index)"
                                    :aria-controls="'content-' + index"
                                    class="relative flex items-center w-full  font-semibold text-left transition-all ease-in border-solid cursor-pointer border-slate-100 text-slate-700 rounded-t-1 group ">
                                    <span class="text-[20px] lg:text-[16px] xl:text-[18px] font-bold font-poppins ">{{
                                        doc.question }}</span>
                                    <i class="absolute right-0 pt-1 text-xs fa"
                                        :class="{ 'fa-plus': !isOpen(index), 'fa-minus': isOpen(index) }"></i>
                                </button>
                            </h6>
                            <transition name="fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
                                <div v-show="isOpen(index)" :id="'content-' + index"
                                    class="overflow-hidden transition-all duration-300 ease-in-out">
                                    <div v-html="doc.answer"
                                        class="lg:text-[16px] prose  font-poppins mt-3 xl:text-body md:text-body text-body_Mobile text-[#6b6b6b] ">
                                     
                                    </div>
                                </div>
                            </transition>
                            <div class="absolute lg:top-3 xl:top-4 right-4 ">
                                <svg @click="toggle(index)"
                                    :class="isOpen(index) ? 'icon-transition icon-open' : 'icon-transition icon-closed'"
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="size-6 xl:size-7 cursor-pointer ">
                                    <path v-if="!isOpen(index)" stroke-linecap="round" class="font-bold"
                                        stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                    <path v-else stroke-linecap="round" class="font-bold" stroke-linejoin="round"
                                        d="M6 18 18 6M6 6l12 12" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- mobile size -->

        <div class="flex justify-center ">
            <div class="lg:hidden    mx-auto w-[95%] md:w-[98%] ">
                <div class="space-y-[10px] text-center">
                    <!-- <h2 class="md:text-[28px] text-[20px] font-[500] font-KhmerMoul text-primery1">សំណួរដែលគេសួរញឹកញាប់
                    </h2> -->
                    <div class=" xl:mt-0  text-center">
                <h1
                    class="text-center text-primery1 xl:text-3xl xl:my-0 md:text-3xl mt-5 lg:text-2xl  text-2xl  font-NotoSansKhmer">
                    សំណួរដែលគេសួរញឹកញាប់
                </h1>
                <div class="border-b-[4px] border-primery1 w-16 lg:w-20 xl:w-24 mx-auto mt-2"></div>

            </div>
                    <div>
                        <p class="text-[16px] md:text-[18px]">សំណួរដែលអតិថិជនពេញនិយមសួរអំពីផលិតផល និងសេវាកម្មពួកយើង។
                        </p>
                    </div>
                </div>

                <!-- Accordion Item -->
                <div
                    class="mt-[24px] grid grid-cols-1 md:grid-cols-2 gap-2 gap-y-4 font-NotoSansKhmer lg:text-body xl:text-body text-textbody xl:h-auto md:w-full md:h-auto w-full rounded-[6px] duration-300 text-body">
                    <div class="relative md:mb-3" v-for="(doc, index) in documents" :key="index">
                        <div class="border-[2px] p-3 rounded-md">
                            <h6 class="mb-0 pr-2 md:pr-1">
                                <button @click="toggle(index)" :aria-expanded="isOpen(index)"
                                    :aria-controls="'content-' + index"
                                    class="relative flex items-center w-full  font-semibold text-left transition-all ease-in border-solid cursor-pointer border-slate-100 text-slate-700 rounded-t-1 group ">
                                    <span class="md:text-[13px] text-[15px]  font-bold ">{{ doc.question }}</span>
                                    <i class="absolute right-0 pt-1 text-xs fa"
                                        :class="{ 'fa-plus': !isOpen(index), 'fa-minus': isOpen(index) }"></i>
                                </button>
                            </h6>
                            <transition name="fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
                                <div v-show="isOpen(index)" :id="'content-' + index"
                                    class="overflow-hidden transition-all duration-300 ease-in-out">
                                    <div v-html="doc.answer" class=" prose mt-3 xl:text-body md:text-[14px] text-[14px] text-[#6b6b6b] ">
                                 
                                    </div>
                                </div>
                            </transition>
                            <div class="absolute top-3 right-1 md:right-1">
                                <svg @click="toggle(index)"
                                    :class="isOpen(index) ? 'icon-transition icon-open' : 'icon-transition icon-closed'"
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="size-5  cursor-pointer ">
                                    <path v-if="!isOpen(index)" stroke-linecap="round" class="font-bold"
                                        stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                    <path v-else stroke-linecap="round" class="font-bold" stroke-linejoin="round"
                                        d="M6 18 18 6M6 6l12 12" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import getCollection from '@/composible/getCollections';

export default {
    data() {
        return {
            openItem: null,
            documents: []
        };
    },
    async created() {
        try {
            const { documents } = await getCollection("FAQ");
            this.documents = documents;
        } catch (error) {
            console.error("Failed to fetch documents:", error);

        }
    },
    methods: {

        toggle(index) {
            this.openItem = this.openItem === index ? null : index;
        },
        isOpen(index) {
            return this.openItem === index;
        },
        beforeEnter(el) {
            el.style.height = '0';
            el.style.opacity = '0';
        },
        enter(el) {
            el.offsetHeight;
            el.style.transition = 'height 0.3s ease-in-out, opacity 0.3s ease-in-out';
            el.style.height = `${el.scrollHeight}px`;
            el.style.opacity = '1';
        },
        leave(el) {
            el.style.transition = 'height 0.3s ease-in-out, opacity 0.3s ease-in-out';
            el.style.height = '0';
            el.style.opacity = '0';
        }
    }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s, height 0.3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.icon-transition {
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.icon-closed {
    opacity: 1;
}

.icon-open {
    transform: rotateX(180deg);
    transform: all 0.3s ease-in-out;

}
</style>