<template>
  <div class="select-none hidden lg:block text_navbar">
    <div class="grid grid-cols-2 p-5" @mouseleave="scheduleClear" @mouseenter="cancelClear">
      <div
        class="space-y-3 flex flex-col lg:text-[14px] xl:text-[17px] bg-white xl:w-[350px] h-full border-l border-t border-b border-r p-6">
        <!-- <h4 class="font-bold lg:text-[18px] xl:text-[22px]">សេវាកម្មអាជីវកម្ម</h4> -->
        <div class="space-y-3 flex flex-col lg:text-[14px] xl:text-[17px] mt-0">
          <div v-for="category in categories" :key="category.name" 
            class="cursor-pointer font-poppins font-[500] flex gap-2 lg:text-[16px] xl:text-[18px] items-center justify-between"
            :class="activeCategory === category.id ? 'text-primery1' : 'hover:text-primery1'"
            @mouseenter="setActiveCategory(category.id)">
            <p class="font-NotoSansKhmer">{{ category.name }}</p>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="size-[18px] font-bold">
              <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
            </svg>
          </div>

          <router-link to="/productsandservices"
            class="cursor-pointer hover:text-primery1 lg:text-[16px] xl:text-[18px]">
            មើលទាំងអស់
          </router-link>
        </div>
      </div>

      <!-- Subcategories Section -->
      <div>
        <ul v-if="products.length > 0"
          class="bg-white lg:w-[370px] xl:w-[580px] border-b border-r border-t p-2 h-full "
          @mouseenter="cancelClear" @mouseleave="scheduleClear">
          <li v-for="(product, index) in products" :key="index" class="py-2 px-3 hover:bg-gray-100 cursor-pointer">
        
            <a :href="`/systemdev/${product.id}`"
              class="hover:text-primery1 line-clamp-1 lg:text-[15px] xl:text-[17px] font-[500]">
              {{ product.name }}
            </a>
          </li>
        </ul>
      </div>
    </div>


  </div>
</template>
<script>
import { ref, watch } from "vue";
import { getCollectionQuery } from "@/composible/getCollection";
import { where } from "@firebase/firestore";
import getCollection from '@/composible/getCollections';


export default {
  setup() {
    const products = ref([]);
    const activeCategory = ref("");
    // const categories = ref([
    //   {id: "1nseQAZYyuZzwobRlI5u", name: "System Development", label: "អភិវឌ្ឍន៍ប្រព័ន្ធគ្រប់គ្រងទិន្នន័យ" },
    //   {id: "Qpu0VxyD4MELRLwTRRUs", name: "Web Design", label: "រចនាគេហទំព័រ" },
    //   {id: "ra1yTG7WvRtntQ9utxru", name: "Business Management System", label: "រៀបចំប្រព័ន្ធគ្រប់គ្រងសម្រាប់អាជីវកម្ម" },
    // ]);
    let clearTimeoutRef = null;
    const categories = ref(null)

    const {documents} = getCollection("categories")

    // watch(documents, () => {
    //   categories.value = documents.value || null
    // })

    const fetchProductsByCategory = async (categoryType) => {
      try {
 
       const {documents} =   getCollection("products", where("cid", "==", categoryType));

       watch(documents, () => {
          products.value = documents.value || null
       })
        
        activeCategory.value = categoryType;
      } catch (error) {
        console.error("Error fetching products:", error.message);
      }
    };


    const setActiveCategory = (categoryType) => {
      if (activeCategory.value !== categoryType) {
        activeCategory.value = categoryType;
        fetchProductsByCategory(categoryType);
      }
      else{
        fetchProductsByCategory('1nseQAZYyuZzwobRlI5u')
      }
    };

    watch(documents,() => {
      fetchProductsByCategory('1nseQAZYyuZzwobRlI5u')
      categories.value = documents.value || null
    })

    const scheduleClear = () => {
      clearTimeoutRef = setTimeout(() => {
        products.value = [];
        // activeCategory.value = "";
        fetchProductsByCategory('1nseQAZYyuZzwobRlI5u')

      }, 50);
    };

    const cancelClear = () => {
      if (clearTimeoutRef) {
        clearTimeout(clearTimeoutRef);
        clearTimeoutRef = null;
      }
    };



    return {
      products,
      activeCategory,
      categories,
      setActiveCategory,
      scheduleClear,
      cancelClear
    };
  },
};
</script>
