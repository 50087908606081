<template>
  <div
    class="flex flex-col items-center justify-center w-full h-screen fixed top-0 right-0 bg-black/70 z-50"
  >
    <div
      v-motion-slide-top
      class="w-[700px] h-auto bg-white shadow-sm rounded-lg p-5 xl:mb-0 lg:mb-[20rem]"
    >
      <div class="w-full mx-auto flex items-center justify-between mb-4">
        <h1 class="text-primery1 text-heading3">
          {{ datatoedit ? "កែប្រែ" : "បន្ថែមថ្មី" }}
        </h1>
        <button
          @click="handleClose"
          class="w-10 h-10 rounded-full focus:border-primery1 focus:border flex items-center justify-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="currentColor"
            class="w-8 h-8 text-primery1"
          >
            <path
              d="M5.28 4.22a.75.75 0 0 0-1.06 1.06L6.94 8l-2.72 2.72a.75.75 0 1 0 1.06 1.06L8 9.06l2.72 2.72a.75.75 0 1 0 1.06-1.06L9.06 8l2.72-2.72a.75.75 0 0 0-1.06-1.06L8 6.94 5.28 4.22Z"
            />
          </svg>
        </button>
      </div>
      <form
        @submit.prevent="handleSubmit"
        class="w-full mx-auto mt-4 space-y-2"
      >
        <div
          class="lg:grid lg:grid-cols-1 xl:grid xl:grid-cols-2 flex items-center justify-between gap-2"
        >
          <div class="space-y-2">
            <label required for="name" class="text-body"
              >ឈ្មោះ<span class="text-red-500">*</span></label
            >
            <input
              required
              class="input"
              name="name"
              type="text"
              placeholder="ឈ្មោះ"
              v-model="name"
            />
          </div>
          <div class="space-y-2">
            <label for="name" class="text-body"
              >ឈ្មោះក្រុមហ៊ុន<span class="text-red-500">*</span></label
            >
            <input
              required
              class="input"
              name="name"
              type="text"
              placeholder="ឈ្មោះក្រុមហ៊ុន"
              v-model="companyname"
            />
          </div>
        </div>

        <div
          class="lg:grid lg:grid-cols-1 xl:grid xl:grid-cols-2 flex items-center justify-between gap-2"
        >
          <div class="space-y-2">
            <label for="name" class="text-body"
              >លេខទូរសព្ទ<span class="text-red-500">*</span></label
            >
            <input
              required
              class="input"
              name="name"
              type="text"
              placeholder="លេខទូរសព្ទ"
              v-model="number"
            />
          </div>
          <div class="space-y-2">
            <label for="name" class="text-body">លេខតេលក្រាម</label>
            <input
              class="input"
              name="name"
              type="text"
              placeholder="លេខតេលក្រាម"
              v-model="numbertelete"
            />
          </div>
        </div>
        <div class="space-y-2">
          <label for="name" class="text-body"
            >អាស័យដ្ឋាន<span class="text-red-500">*</span></label
          >
          <input
            class="input"
            name="name"
            type="text"
            placeholder="អាស័យដ្ឋាន"
            v-model="address"
          />
          <div></div>
        </div>
        <label for="name" class="text-body"
          >ជ្រើរសើសស្ថានភាព<span class="text-red-500">*</span></label
        >
        <div class="input">
          <select required class="w-full bg-transparent" v-model="status">
            <!-- Add the "Test" option as the first option -->
            <!-- <option value="Test"></option> -->

            <!-- Loop through the data array for other options -->
            <option v-for="option in statusType" :value="option" :key="option">
              {{ option }}
            </option>
          </select>
        </div>
        <label for="name" class="text-body"
          >ជ្រើរសើសវាកម្ម<span class="text-red-500">*</span></label
        >
        <div class="input">
          <select required class="w-full bg-transparent" v-model="itemsType">
            <!-- Add the "Test" option as the first option -->
            <!-- <option value="Test"></option> -->

            <option v-for="option in category" :key="option">
              {{ option.name }}
            </option>
          </select>
        </div>
        <div
          v-if="rejectionReason !== '' && rejectionReason !== undefined"
          class="space-y-2"
        >
          <label required for="name" class="text-body">មូលហេតុ</label>
          <input
            required
            class="input"
            name="name"
            type="text"
            placeholder="មូលហេតុ"
            v-model="rejectionReason"
          />
        </div>
        <div v-else></div>
        <div class="space-y-2">
          <label for="name" class="text-body"
            >គោលបំណង<span class="text-red-500">*</span></label
          >
          <textarea
            required
            class="input"
            placeholder="គោលបំណង"
            name=""
            id=""
            cols="30"
            rows="5"
            v-model="pospurse"
          ></textarea>
        </div>
        <div class="flex w-full">
          <button class="box-btn text-borderBody mx-auto px-12 mt-4">
            កែប្រែ
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import useCollection from "@/composible/useCollection";
import { onMounted, ref } from "vue";
import { timestamp } from "@/firebase/config";
import { getCollectionQuery } from "@/composible/getCollection";
export default {
  emit: ["AddSusccesfully", "UpddateSuccess"],
  props: ["datatoedit"],
  setup(props, { emit }) {
    const { addDocs, removeDoc, updateDocs } = useCollection("inbox");
    const status = ref("");
    const category = ref([]);
    const itemsType = ref("");
    const name = ref("");
    const companyname = ref("");
    const number = ref("");
    const numbertelete = ref("");
    const address = ref("");
    const pospurse = ref("");
    const rejectionReason = ref("");
    const statusType = ref(["Pending", "Rejected", "Answered"]);
    const getDataCategory = async () => {
      try {
        await getCollectionQuery(
          "categories",
          [],
          (data) => {
            category.value = data;
          },
          true
        );
      } catch (error) {
        console.error(error.message);
      }
    };
    onMounted(() => {
      getDataCategory();
    });
    const rejectionReasonValue =
      rejectionReason.value !== undefined ? rejectionReason.value : "N/A";
    const handleSubmit = async () => {
      loading.value = true;

      const productData = {
        name: name.value,
        companyname: companyname.value,
        number: number.value,
        numbertelete: numbertelete.value,
        address: address.value,
        status: status.value,
        itemsType: itemsType.value,
        pospurse: pospurse.value,
        rejectionReason: rejectionReasonValue,
        createdAt: timestamp(),
      };
      if (props.datatoedit) {
        const upadateSuccess = await updateDocs(
          props.datatoedit?.id,
          productData
        );
        if (upadateSuccess) {
          emit("UpddateSuccess");
        }
      } else {
        const success = await addDocs(productData);

        if (success) {
          emit("AddSusccesfully");
        }
      }
      handleClose();
    };

    const handleClose = () => {
      emit("close");
      name.value = "";
      companyname.value = "";
      number.value = "";
      numbertelete.value = "";
      itemsType.value = "";
      status.value = "";
      pospurse.value = "";
    };
    onMounted(() => {
      if (props.datatoedit) {
        name.value = props.datatoedit.name;
        companyname.value = props.datatoedit.companyname;
        number.value = props.datatoedit.number;
        numbertelete.value = props.datatoedit.numbertelete;
        itemsType.value = props.datatoedit.itemsType;
        status.value = props.datatoedit.status;
        pospurse.value = props.datatoedit.pospurse;
        address.value = props.datatoedit.address;
        rejectionReason.value = props.datatoedit.rejectionReason;
      }
    });
    const loading = ref(false);
    return {
      handleClose,
      handleSubmit,
      loading,
      itemsType,
      category,
      name,
      companyname,
      number,
      numbertelete,
      address,
      pospurse,
      status,
      statusType,
      rejectionReason,
    };
  },
};
</script>
