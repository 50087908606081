<template>
    <div class="flex flex-col py-4 -mt-10 ">

        <!-- <video width="320" height="240" controls>
                        <source :src="pro.video" type="video/mp4">

                    </video>
                     -->

        <div v-for="pro in product" :key="pro.id">
            <div class="w-[100%]  md:w-[97%] xl:w-[1280px] lg:w-[90%] mx-auto md:space-y-4 xl:px-4 px-4 md:px-0">
                <div class="lg:mt-[32px] xl:mt-[32px]  mt-0 text-start lg:p-0 xl:p-0 p-3 ">
                    <h2
                        class="font-NotoSansKhmer lg:ml-0 md:ml-3 text-primery1 font-bold xl:text-heading2 lg:text-heading2 md:text-[20px] text-[18px] xl:text-start lg:text-start md:text-start text-center">
                        {{ pro.name }}
                    </h2>




                </div>
                <div class="lg:mt-[32px] xl:mt-[32px]  mt-0 text-start lg:p-0 xl:p-0 p-3 ">
                    <video v-if="pro.video" controls
                        class="border-double border-4 border-indigo-600 rounded-md w-full md:w-[50%] md:h-[400px]  object-cover ">
                        <source :src="pro.video" class="h-[30px] " type="video/mp4">
                    </video>

                </div>
                <div
                    class="w-full  md:mx-0 lg:ml-0 max-w-[1420px] py-1 lg:p-0 xl:p-0 p-3 md:ml-3 xl:flex lg:flex md:flex block gap-8 space-y-2 xl:space-y-0 lg:space-y-0 md:space-y-0">
                    <!-- <div class="xl:w-1/2 lg:w-1/2 md:w-1/2 block">
              <img :src="product.image" alt="" class="rounded-[6px]" />
            </div> -->
                    <div class="xl:w-[1420px]  block">
                        <p
                            class="text-textbody px-2 md:px-0 md:-mt-5 lg:-mt-0 xl:text-body lg:text-[16px] md:text-[16px] text-[14px] font-[500]">
                            {{ pro.bussinessDescription
                            }}
                        </p>
                    </div>
                </div>

                <!-- computer ipad-->
                <div v-for="(productfeatures, index) in pro.feature" :key="index"
                    class="w-full max-w-[1280px] py-4 lg:p-0 xl:p-0 p-3 md:p-3 space-y-4 xl:mt-[48px] xl:block lg:block md:block ">
                    <!-- details1 -->

                    <div :class="{
                        'w-full lg:flex lg:flex-row   xl:flex xl:flex-row md:flex   flex flex-col-reverse items-start justify-center  space-y-4 xl:gap-12 lg:gap-12 md:gap-12 gap-5 py-3':
                            index % 2 === 0,
                        'w-full lg:flex lg:flex-row-reverse xl:flex-row-reverse xl:flex md:flex  flex flex-col-reverse items-start justify-center space-y-4 xl:gap-12 lg:gap-12 md:gap-12 gap-5 py-3':
                            index % 2 !== 0,

                    }">
                        <div class="lg:w-1/2 xl:w-full md:w-[95%] md:mx-auto w-full mt-[2px]">
                            <h2
                                class="text-primery1 md:mb-2 font-bold font-NotoSansKhmer text-heading3 xl:text-start lg:text-start md:text-start text-center">
                                {{ productfeatures.title }}
                            </h2>
                            <div class="flex flex-col items-center justify-center w-[90%]">
                                <div
                                    class="text-textbody xl:text-[16px] lg:text-[16px] md:text-body text-[14px] text-start font-[500]">

                                    <!-- <div class="flex ">
                                        {{ productfeatures.details }}
                                    </div> -->

                                    <!-- pos system -->
                                    <div v-if="pro.name == 'POS System'">
                                        <div v-if="index % 2 === 0">
                                            <div v-if="productfeatures.title == 'មុខងារសំខាន់ៗ'" class="w-full">
                                                <div class="">
                                                    <div
                                                        class="md:grid md:grid-cols-3 lg:gap-x-8 md:gap-x-10 sm:grid-cols-3 gap-x-10 xl:gap-x-11 gap-y-4 lg:grid-cols-2 lg:grid lg:justify-between xl:grid-cols-3  grid grid-cols-2 mt-3">

                                                        <li>គ្រប់គ្រងទំនិញ</li>
                                                        <li class="text-nowrap">គ្រប់គ្រងស្តុកទំនិញនិងឃ្លាំង</li>
                                                        <li>គ្រប់គ្រងសេវាកម្ម</li>
                                                        <li>គ្រប់គ្រងការទិញចូល</li>
                                                        <li class="text-nowrap">គ្រប់គ្រងការលក់</li>
                                                        <li>គ្រប់គ្រងអ្នកផ្គត់ផ្គង់</li>
                                                        <li>គ្រប់គ្រងភ្នាក់ងារ</li>
                                                        <li>គ្រប់គ្រងអតិថិជន</li>
                                                        <li class="text-nowrap">គ្រប់គ្រងបុគ្គលិក</li>
                                                        <li>គ្រប់គ្រងអ្នកប្រើប្រាស់</li>
                                                        <li class="text-nowrap">គ្រប់គ្រងការកក់និងគម្រោង</li>
                                                        <li class="text-nowrap">ការកំណត់តួនាទីនិងសិទ្ធិ</li>
                                                        <li class="text-nowrap">ការលក់ផ្តល់កម្រៃជើងសារ</li>
                                                        <li>ផ្ទាំងលំអិតប្រតិបត្តការ</li>
                                                        <li>គ្រប់គ្រងចំណាយ</li>
                                                        <li>គ្រប់គ្រងការជំពាក់</li>
                                                        <li>កំណត់លក្ខ័ណ្ឌទូទាត់</li>
                                                        <li class="text-nowrap">កំណត់ពន្ធ, រូបិយប័ណ្ណ, ភាសា</li>
                                                        <li>គ្រប់គ្រងសាខា</li>
                                                        <li>របាយការណ៍ផ្សេងៗ</li>

                                                    </div>

                                                </div>
                                            </div>

                                            <div v-else>
                                                <p>1. ជួយគ្រប់គ្រង តម្លៃទំនិញ ស្តុកទំនិញ ប្រវត្តទិញចូល លក់ចេញ ច្បាស់លាស់
                                                </p>
                                                <p>2. ជួយឱ្យចេញវិក័យបត្រលឿន សរុបចំណូល ចំណាយ ចំណេញ និងប្រាក់ជំពាក់បានលឿន
                                                    និងច្បាស់លាស់</p>
                                                <p>3. ជួយឱ្យឈប់រវល់ច្រើន ឈប់ឈឺក្បាលច្រើន ឈប់មានតែភាពមិនច្បាស់
                                                    ទៅជាងាយស្រួលនិងរីកចម្រើន</p>
                                                <p>4. ជួយបង្កើនភាពស្តង់ដារ និងបង្កើនប្រាក់ចំណេញដល់អាជីវកម្ម</p>
                                                <p>5. ជួយឱ្យអាជីវកម្មដំណើរការបានរលូន កាត់បន្ថយការបាត់បង់
                                                    និងភាពខ្ចះខ្ចាយ។
                                                </p>
                                                <p>6. ជួយឱ្យអ្នកគ្រប់គ្រងអាជីវកម្មបានកាន់តែទូលំទូលាយ លើសពី POS ធម្មតា ។
                                                </p>
                                            </div>

                                        </div>

                                        <div v-if="index % 2 !== 0" class="space-y-2 mt-2 md:mt-0">

                                            <div v-if="productfeatures.title == 'លក្ខណៈពិសេស'">
                                                <div class="space-y-1">
                                                    <li>អាចគ្រប់គ្រងបានច្រើនសាខា ក្នុងប្រព័ន្ធតែមួយ</li>
                                                    <li>អាចបង្កើតបញ្ជីអ្នកប្រើប្រាស់ និងកំណត់សិទ្ធយ៉ាងសម្បូបែប</li>
                                                    <li>អាចបង្កើតទំនិញឬសេវាកម្មទៅតាមប្រភេទ តាមក្រុម តាមពណ៌ តាមទំហំ
                                                        តាមខ្នាតតាមម៉ាក</li>
                                                    <li>អាចកំណត់តម្លៃទំនិញលក់ដុំ លក់រាយ តម្លៃតាមប្រភេទផលិតផល
                                                        តម្លៃតាមប្រភេទអតិថិជន</li>
                                                    <li>អាចកំណត់ការធានាលើទំនិញ
                                                        គ្រប់គ្រងទំនិញហួសកាលកំណត់និងគ្រប់គ្រងទំនិញជិតអស់ពីស្តុក</li>
                                                    <li>អាចគ្រប់គ្រង ប្រវត្តស្តុកទំនិញលំអិត​
                                                        និងអាចផ្ទេរស្តុកពីឃ្លាំងមួយទៅឃ្លាំងមួយតាមសាខានីមួយៗ</li>
                                                    <li>អាចបញ្ជាទិញ (Purchase Order, Sale Order) ចេញសំណើរទិញលក់
                                                        (Quotation) និងអាចលក់រហ័ស</li>
                                                    <li>អាចបង្កើតកម្មវិធីលក់បញ្ចុះតម្លៃ
                                                        បង្កើតមធ្យោបាយទូទាត់ប្រាក់បង្កើតការលក់ផ្តល់កម្រៃជើងសារ</li>
                                                    <li>អាចលក់ចេញវិក័យបត្រច្រើនទម្រង់ ដែលអាចបញ្ចូលការបញ្ចុះតម្លៃ ពន្ធ
                                                        និងបន្ថែមចំណាយផ្សេងៗ</li>
                                                    <li>អាចលក់ផ្អាក លក់ជំពាក់ លក់ត្រលប់ អាចទូទាត់សងតាមវិក័យបត្រ ឬសងសរុប
                                                    </li>
                                                    <li>អាចបញ្ចូលព័ត៌មាននៃការដឹកជញ្ជូនទំនិញ និងតាមដានការដឹកជញ្ជូនទំនិញ
                                                    </li>
                                                    <li>អាចចុះចំណាយប្រចាំថ្ងៃ និងគ្រប់គ្រងចំណូល ចំណាយ ចំណេញ ជំពាក់
                                                        ដោយមានរបាយការណ៍ច្បាស់</li>
                                                    <li class="xl:text-nowrap">អាចបន្ថែមមុខងារគ្រប់គ្រងផលិតកម្ម,
                                                        ធនធានមនុស្ស, គ្រប់គ្រងអតិថិជន,
                                                        និងគណនេយ្យ។</li>

                                                </div>
                                            </div>
                                            <div v-if="productfeatures.title == 'គ្រប់គ្រងរបាយការណ៍'">
                                                <div class="space-y-2 xl:-ml-[50px]">
                                                    <li>របាយការណ៍ចំណូល - ចំណាយ-ចំណេញ (Profit/Loss Report)</li>
                                                    <li>របាយការណ៍ទិញ-លក់ (Purchase & Sale)</li>
                                                    <li>របាយការណ៍ទិញផលិត (Product Purchase Report)</li>
                                                    <li>របាយការណ៍លក់និងកំរ៉ៃជើងសារ (Sales Representative Report)</li>
                                                    <li>របាយការណ៍ចំណាយ (Expense Report)</li>
                                                    <li>របាយការណ៍ទូទាត់ប្រាក់របស់អតិថិជន (Sell Payment Report)</li>
                                                    <li>របាយការទូទាត់ប្រាក់ជាមួយអ្នកផ្គត់ផ្គង់ (Purchase Payment Report)
                                                    </li>
                                                    <li>របាយការណ៍ផលិតផល (Items Report)</li>
                                                    <li>របាយការណ៍ផលិតផលពេញនិយម (Trending Products)</li>
                                                    <li>របាយការណ៍ស្តុកចេញ ស្តុកចូល វាយតម្លៃទំនិញ និងលទ្ធផល (Stock
                                                        Report)</li>
                                                    <li>របាយការណ៍កែតម្រូវស្តុក (Stock Adjustment Report)</li>
                                                    <li>បាយការណ៍អ្នកផ្គត់ផ្គង់ និងអតិថិជន (Supplier & Customer Report)
                                                    </li>
                                                    <li>របាយការណ៍អតិថិជនជាក្រុម (Customer Groups Report)</li>
                                                    <li>របាយការណ៍បង្វិលទំនិញទៅកាន់អ្នកផ្គត់ផ្គង់</li>
                                                    <li>របាយការណ៍ការកែប្រែទំនិញខូច និងហួសកំណត់</li>
                                                    <li>របាយការណ៍ផ្ទេរស្តុកពីសាខាមួយទៅសាខាមួយ</li>
                                                    <li>របាយការណ៍ពន្ធដារ</li>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- school management system -->
                                    <div v-else-if="pro.name == 'School Management System'">
                                        <div v-if="index % 2 === 0">
                                            <div v-if="productfeatures.title == 'គោលបំណង'" class="w-full">
                                                <div class="">

                                                    <div>
                                                        <p>1. ជួយបង្កើនប្រសិទ្ធិភាព
                                                            នៃការគ្រប់គ្រងប្រតិបត្តការសាលារៀនឱ្យដំណើរការរលូន។
                                                        </p>
                                                        <p>2. ជួយឱ្យរាល់កិច្ចការទាំងអស់នៅក្នុងសាលារៀន
                                                            ត្រូវបានគ្រប់គ្រងក្នុងប្រព័ន្ធតែមួយ។</p>
                                                        <p>3. ជួយធ្វើឱ្យមានភាពច្បាស់លាស់នៃទិន្នន័យ មានសុច្ចរិតភាព
                                                            និងកាត់បន្ថយចំណាយ។</p>
                                                        <p>4. ជួយបង្កើនទំនាក់ទំនងបានល្អ ច្បាស់លាស់ លឿនរហ័ស រវាង
                                                            សិស្សឬអាណាព្យាបាលសិស្ស គ្រូ រដ្ឋបាល និងអ្នកគ្រប់គ្រង។</p>
                                                        <p>5. ជួយបង្កើនគុណភាពអប់រំ និងកេត្តិរ៍នាមរបស់សាលាឱ្យល្បីល្បាញ។
                                                        </p>
                                                        <p>6. ជួយឱ្យអ្នកគ្រប់គ្រង វិភាគទិន្នន័យ វាយតម្លៃលទ្ធផល
                                                            សម្រេចចិត្ត បានស្រួល លឿន ច្បាស់។
                                                        </p>
                                                        <p>7. ជួយឱ្យងាយស្រួលតាមដានស្ថានភាពហិរញ្ញវត្ថុ
                                                            និងបង្កើនប្រាក់ចំណេញ។</p>

                                                        <div>

                                                            <h2
                                                                class="font-NotoSansKhmer my-4   lg:ml-0 md:ml-0 text-primery1 font-bold xl:text-[20px] lg:text-[20px] md:text-[20px] text-[18px] xl:text-start lg:text-start md:text-start text-center">
                                                                សាកសមសម្រាប់
                                                            </h2>
                                                            <div>
                                                                <li>សាលារដ្ឋ (បឋម-វិទ្យាល័យ), សាលាឯកជន (ចំណេះដឹងទូទៅ,
                                                                    ភាសា, ជំនាញ)</li>
                                                                <li>សាលាបណ្តុះបណ្តាលជំនាញបច្ចេកទេស, វិទ្យាស្ថាន
                                                                    និងសកលវិទ្យាល័យ។</li>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div v-if="productfeatures.title == 'មុខងារសំខាន់ៗ'" class="w-full">
                                                <div class="">
                                                    <div
                                                        class="md:grid md:grid-cols-3 lg:gap-x-8 md:gap-x-10 sm:grid-cols-3 gap-x-10 xl:gap-x-11 gap-y-4 lg:grid-cols-2 lg:grid lg:justify-between xl:grid-cols-3  grid grid-cols-2 mt-3">

                                                        <li class="text-nowrap">គ្រប់គ្រងកន្លែងផ្តល់ព័ត៌មាន</li>
                                                        <li class="text-nowrap">គ្រប់គ្រងព័ត៌មានសិស្ស</li>
                                                        <li class="text-nowrap">គ្រប់គ្រងការបង់ប្រាក់</li>
                                                        <li class="text-nowrap">គ្រប់គ្រងរដ្ឋបាល</li>
                                                        <li class="text-nowrap">គ្រប់គ្រងវត្តមាន</li>
                                                        <li class="text-nowrap">គ្រប់គ្រងគណនេយ្យ</li>
                                                        <li class="text-nowrap">គ្រប់គ្រងធនធានមនុស្ស</li>
                                                        <li class="text-nowrap">គ្រប់គ្រងកិច្ចការងារសាលា</li>
                                                        <li class="text-nowrap">គ្រប់គ្រងកម្មវិធីសិក្សា</li>
                                                        <li class="text-nowrap">គ្រប់គ្រងបណ្ណាល័យ</li>
                                                        <li class="text-nowrap">គ្រប់គ្រងការប្រលង</li>
                                                        <li class="text-nowrap">គ្រប់គ្រងផែនការមេរៀន</li>
                                                        <li class="text-nowrap">គ្រប់គ្រងការស្នាក់នៅ</li>
                                                        <li class="text-nowrap">គ្រប់គ្រងវិញ្ញាបនបត្រ</li>
                                                        <li class="text-nowrap">គ្រប់គ្រងសិទ្ធប្រើប្រាស់</li>
                                                        <li class="text-nowrap">គ្រប់គ្រងការដឹកជញ្ជូន</li>
                                                        <li class="text-nowrap">គ្រប់គ្រងរបាយការណ៍</li>
                                                        <li class="text-nowrap">គ្រប់គ្រងសាខា</li>

                                                    </div>

                                                </div>
                                            </div>




                                        </div>

                                        <div v-if="index % 2 !== 0">
                                            <div v-if="productfeatures.title == 'លក្ខណៈពិសេសរបស់ប្រព័ន្ធ'"
                                                class="xl:ml-8">
                                                <div class="space-y-2 xl:-ml-[50px] ">
                                                    <li>អាចគ្រប់គ្រងបានច្រើនសាខា</li>
                                                    <li>អាចប្រើប្រាស់ទាំង Offline, Online, និងទូរស័ព្ទ</li>
                                                    <li>អាចគ្រប់គ្រងបានគ្រប់ផ្នែក :
                                                        <br>
                                                        <span class="ml-10">- សិស្សនិងអាណាព្យាលបាល, គ្រូ,
                                                            ផ្នែកផ្តល់ព័ត៌មាន</span>
                                                        <br>
                                                        <span class="ml-10">- រដ្ឋបាល, គណនេយ្យ, ធនធានមនុស្ស, </span>
                                                        <br>
                                                        <span class="ml-10">- បណ្ណាល័យ, ផ្នែកដឹកជញ្ជូន,
                                                            និងកន្លែងស្នាក់នៅ</span>
                                                        <br>

                                                    </li>
                                                    <li>អាចរៀបចំកម្មវិធីសិក្សា បង្កើតថ្នាក់ និងកាលវិភាគថ្នាក់
                                                        បាត់បែនតាមតម្រូវការ </li>
                                                    <li>អាចគ្រប់គ្រង សិស្សសួរព័ត៌មាន សិស្សបានចុះឈ្មោះ ការបង់ប្រាក់
                                                        មានប្រសិទ្ធភាព</li>
                                                    <li>អាចគ្រប់គ្រងកម្មវិធីសិក្សាអនឡាញយ៉ាងសម្បូរបែប</li>
                                                    <li>អាចគ្រប់គ្រងវិភាគ ឥរិយាបថសិក្សារបស់សិស្ស ងាយស្រួល
                                                    </li>
                                                    <li>អាចកត់វត្តមានសិស្ស គ្រូ និងបុគ្គលិកបានច្រើនវិធីសាស្រ្ត</li>
                                                    <li>អាចគ្រប់គ្រងគុណភាពអប់រំ និងគុណភាពការប្រលង មានប្រសិទ្ធភាព</li>
                                                    <li>
                                                        អាចរចនា រៀបចំកាត និងវិញ្ញាបនបត្រសម្រាប់សិស្សឬបុគ្គលិក
                                                        តាមតម្រូវការ</li>
                                                    <li>មានកម្មវិធីជំរុញទីផ្សារនិងការលក់ យ៉ាងសម្បូបែប</li>
                                                    <li>អាចគ្រប់គ្រងសម្ភារៈការិយាល័យ ចំណូល ចំណាយ និងធនធានមនុស្ស
                                                        មានប្រសិទ្ធភាព
                                                    </li>

                                                </div>
                                            </div>


                                        </div>

                                    </div>

                                    <!-- loan management system -->
                                    <div v-else-if="pro.name == 'Loan Management System'"
                                        class="w-full bg-blue md:mr-[30rem] xl:mr-[25rem] lg:mr-[20rem]">
                                        <div v-if="index % 2 === 0" class="flex justify-start">
                                            <div v-if="productfeatures.title == 'មុខងារការកំណត់ផ្សេងៗ'"
                                                class="space-y-1 mt-2">
                                                <p>1. អាចប្រើប្រាស់បានច្រើនសាខា
                                                </p>
                                                <p>2. កំណត់ថ្ងៃធ្វើការតាមសាខានីមួយៗ</p>
                                                <p>3. គ្រប់គ្រងព័ត៌មានបុគ្គលិកឥណទាន</p>
                                                <p>4. កំណត់ឲ្យមានការគេចថ្ងៃឈប់សម្រាក</p>
                                                <p>5. កំណត់អត្រាប្តូរប្រាក់
                                                </p>
                                                <p>6. កំណត់ប្រភេទប្រាក់គោល (ដុល្លារ, ខ្មែរ, បាត…)
                                                </p>
                                                <p>7. ផ្លាស់ប្តូរភាសា (ខ្មែរ, អង់គ្លេស)</p>
                                                <p>8. ការផាកពិន័យ</p>
                                                <p>9. ការផាកពិន័យលើការបង់ផ្ដាច់</p>
                                            </div>

                                            <div v-if="productfeatures.title == 'មុខងារអតិថិជន'" class="mt-2 space-y-1">
                                                <li>ព័ត៌មានអតិថិជន</li>
                                                <li>ឯកសាររបស់អតិថិជន</li>
                                                <li>អ្នកធានា</li>
                                                <li>កំណត់សម្គាល់ឯកសារទ្រព្យបញ្ចាំ</li>
                                                <li>ការស្វែងរកអតិថិជន</li>
                                                <li>ប្រវត្តិកម្ចីរបស់អតិថិជន</li>
                                                <li>កំណត់ក្រេឌីតអតិថិជន (មិនល្អ, ធម្មតា, ល្អ)</li>

                                            </div>


                                            <div v-if="productfeatures.title == 'មុខងាររបាយការណ៍'"
                                                class="mt-2 space-y-2">
                                                <li>របាយការណ៍ប្រមូលប្រាក់</li>
                                                <li>របាយការណ៍ប្រមូលប្រាក់សម្រាប់អតិថិជនយឺត</li>
                                                <li>របាយការណ៍បង់ប្រាក់របស់អតិថិជន </li>
                                                <li>របាយការណ៍ការបើកគណនីកម្ចី</li>
                                                <li>របាយការណ៍ការរៀបចំតារាងពេលវេលាបង់ប្រាក់សារជាថ្មី</li>
                                                <li>របាយការណ៍ការបង់ប្រាក់ផ្តាច់របស់អតិថិជន</li>
                                                <li>របាយការណ៍ការព្យាករណ៍គម្រោងបង់ប្រាក់</li>
                                                <li>របាយការណ៍អតិថិជន</li>
                                                <li>របាយការណ៍វាយតម្លៃរបស់មន្រ្តីឥណទានជាភាគរយ</li>
                                                <li>របាយការណ៍ផលិតផលកម្ចី</li>

                                            </div>



                                        </div>

                                        <div v-if="index % 2 !== 0">
                                            <div
                                                v-if="productfeatures.title == 'មុខងារគ្រប់គ្រង់សិទ្ធអ្នកប្រើប្រាស់លើប្រព័ន្ធ'">
                                                <div class="space-y-1 mt-2">
                                                    <li>ព័ត៌មានអ្នកប្រើប្រាស់</li>
                                                    <li>កំណត់សិទ្ធិនិងតួនាទីរបស់អ្នកប្រើប្រាស់នីមួយៗនិងតាមសាខា</li>
                                                    <li>កំណត់ថ្ងៃធ្វើការ </li>
                                                    <li>កំណត់គោលដៅបុគ្គលិកឥណទាន</li>

                                                </div>
                                            </div>
                                            <div v-else>
                                                <div class="space-y-1 mt-2">
                                                    <li>ផលិតផលកម្ចី (Loan Product)</li>
                                                    <li>ប្រភេទកម្ចី (ប្រចាំថ្ងៃ, ប្រចាំសប្តាហ៍, ប្រចាំខែ, ប្រចាំឆ្នាំ)
                                                    </li>
                                                    <li>របៀបសងត្រលប់ (Straight Line, Declining, Annuity)</li>
                                                    <li>ថ្លៃសេវាកម្ម (សេវាកម្មរដ្ឋបាល, សេវាប្រតិបត្តិការ, ប្រាក់ពិន័យ
                                                        និងផ្សេងៗ)</li>
                                                    <li>កំណត់ការពិន័យលើការបង់យឺត</li>
                                                    <li>ការបើកគណនីកម្ចី (Open Disbursement)</li>
                                                    <li>តារាងបង់ប្រាក់របស់អតិថិជន</li>
                                                    <li>ការបង់ប្រាក់របស់អតិថិជន (Repayment, Prepay, Pay off, Reschedule,
                                                        Write off)</li>
                                                    <li>រៀបចំតារាងកម្ចីសារជាថ្មីម្តងទៀត (តាមការស្នើសុំរបស់អតិថិជន) </li>
                                                    <li>កិច្ចសន្យាកម្ចី</li>
                                                    <li>ផ្ទេរទិន្នន័យកម្ចីពីកម្មវិធី Excel ចូលក្នុងប្រព័ន្ធ</li>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                    <!-- rental room -->
                                    <div v-else-if="pro.name == 'Rental Management System'">

                                        <div v-if="index % 2 === 0">
                                            <div v-if="productfeatures.title == 'គោលបំណង'">
                                                <div class="space-y-1 mt-2">
                                                    <p>1. ជួយបង្កើនប្រសិទ្ធភាព គ្រប់គ្រងចំណូល ចំណាយ ចំណេញបានច្បាស់</p>
                                                    <p>2. ជួយធ្វើឱ្យប្រតិបត្តការអាជីវកម្មជួលបន្ទប់សា្នក់នៅ ដំណើរការរលូន
                                                    </p>
                                                    <p>3. ជួយបង្កើនប្រសិទ្ធភាព គ្រប់គ្រងចំណូល ចំណាយ ចំណេញបានច្បាស់</p>
                                                    <p>4. ជួយធ្វើឱ្យងាយស្រួលគ្រប់គ្រងការបង់ប្រាក់ ការចេញវិក័យបត្រ
                                                        និងការជូនដំណឹង</p>
                                                    <p>5. ជួយឱ្យចំណេញពេលវេលា ចំណេញថវិការ
                                                        និងជួយដោះស្រាយរាល់ការលំបាកក្នុងការគ្រប់គ្រង</p>
                                                    <p>6. ជួយបង្កើនភាពពេញចិត្តដល់អតិថិជន
                                                        និងផ្តល់បទពិសោធន៍ស្នាក់នៅដ៏ល្អពីអាជីវកម្មអ្នក។</p>
                                                </div>
                                                <div>
                                                    <h2
                                                        class="text-primery1 md:mb-2 font-bold font-NotoSansKhmer text-heading3 xl:text-start lg:text-start md:text-start text-center my-3">
                                                        លក្ខណៈពិសេសរបស់ប្រព័ន្ធ</h2>
                                                    <div class="space-y-1">
                                                        <li>អាចកំណត់ប្រភេទបន្ទប់ និងប្រភេទតម្លៃជួល</li>
                                                        <li>អាចបញ្ចូលព័ត៌មានកុងត្រា ប្រាក់កក់ និងគីឡូទឹកភ្លើងចាស់</li>
                                                        <li>អាចជូនដំណឹងមុនថ្ងៃបង់ប្រាក់ និងជិតដល់ថ្ងៃផុតកុងត្រា</li>
                                                        <li>អាចចេញវិក័យបត្រថ្លៃបន្ទប់ រួមនឹងចំណាយផ្សេងៗ</li>
                                                        <li>អាចគ្រប់គ្រងការទូទាត់ និងការជំពាក់</li>
                                                        <li>អាចកត់ត្រាស្តុកឧបករណ៍ប្រើប្រាស់ផ្សេងៗ</li>
                                                        <li>អាចចុះចំណាយ និងបើកប្រាក់បៀរវត្សបុគ្គលិក</li>
                                                        <li>មានរបាយការណ៍ឆ្លាតវៃ និងផ្ទាំងវិភាគស្ថានភាព</li>

                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else>
                                                fe3
                                            </div>

                                        </div>

                                        <div v-if="index % 2 !== 0">
                                            <div v-if="productfeatures.title == 'មុខងារសំខាន់ៗ'">
                                                <div class="">
                                                    <div
                                                        class="md:grid md:grid-cols-3 lg:gap-x-8 md:gap-x-10 sm:grid-cols-3 gap-x-10 xl:gap-x-11 gap-y-4 lg:grid-cols-2 lg:grid lg:justify-between xl:grid-cols-3  grid grid-cols-2 mt-3">

                                                        <li class="text-nowrap">គ្រប់គ្រងបន្ទប់</li>
                                                        <li class="text-nowrap">គ្រប់គ្រងអ្នកជួល</li>
                                                        <li class="text-nowrap">គ្រប់គ្រងទឹកភ្លើង</li>
                                                        <li class="text-nowrap">គ្រប់គ្រងការបង់ប្រាក់</li>
                                                        <li class="text-nowrap">គ្រប់គ្រងការចេញវិក្កយបត្រ</li>
                                                        <li class="text-nowrap">គ្រប់គ្រងស្តុកសំភារៈ</li>
                                                        <li class="text-nowrap">គ្រប់គ្រងបុគ្គលិក</li>
                                                        <li class="text-nowrap">គ្រប់គ្រងប្រាក់ខែបុគ្គលិក</li>
                                                        <li class="text-nowrap">គ្រប់គ្រងការចំណាយ</li>
                                                        <li class="text-nowrap">គ្រប់គ្រងការជូនដំណឹង</li>
                                                        <li class="text-nowrap">គ្រប់គ្រងបញ្ជីសមាជិក</li>
                                                        <li class="text-nowrap">គណនី</li>
                                                        <li class="text-nowrap">គ្រប់គ្រងរបាយការណ៍</li>
                                                        <li class="text-nowrap">ផ្ទាំងបង្ហាញរយៈពេលកុងត្រា</li>
                                                        <li class="text-nowrap">ការកំណត់ផ្សេងៗ</li>


                                                    </div>

                                                </div>
                                            </div>

                                        </div>


                                    </div>



                                </div>



                            </div>
                        </div>
                        <div class="lg:w-1/2 xl:w-full md:w-[95%] w-full md:mx-auto">
                            <img class="md:w-[896px]   md:h-[400px] object-contain lg:w-[516px] lg:h-[300px] xl:w-[660px] xl:h-[400px] rounded-[6px] "
                                :src="productfeatures.image" />
                        </div>
                    </div>
                </div>
                <!-- mobile  -->
                <div v-for="(productfeatures, index) in product.feature" :key="index"
                    class="w-full lg:hidden md:hidden block xl:hidden">
                    <!-- details1 -->

                    <div class="w-[90%] mx-auto mt-4">
                        <div class="w-full">
                            <h2 class="text-primery1 font-NotoSansKhmer text-[18px]  font-semibold text-center">
                                {{ productfeatures.title }}
                            </h2>
                            <div>
                                <p class="text-textbody text-[14px] text-start font-[500]">
                                    {{ productfeatures.details }}
                                </p>
                            </div>
                        </div>
                        <div class="w-full mt-8">
                            <img class="w-full rounded-[6px]" :src="productfeatures.image" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full h-[48px]"></div>
    </div>

    <div style="background-color: rgba(144, 144, 144, 0.15);"
        class="w-full max-w-full  h-auto py-12 flex flex-col items-center justify-center ">
        <div class="lg:w-[1024px] xl:w-[1280px] md:w-full w-full h-auto">
            <div class=" lg:w-[1024px] xl:w-[1280px] w-full md:w-full mx-auto xl:px-4">
                <div class="lg:-mt-[3rem]  md:-mt-[2rem] text-center">
                    <h2
                        class="font-NotoSansKhmer font-bold -mt-4 text-primery1 md:mt-3 lg:mt-10 text-heading4 md:text-[26px]  lg:text-[24px] xl:text-[26px]">
                        អ្នកអាចសួរសំណួរនៅទីនេះ
                    </h2>
                </div>
                <form @submit.prevent="handleSubmit"
                    class="w-[90%]  xl:w-[100%] lg:w-[95%] md:w-[90%] mx-auto mt-7 lg:mt-5 space-y-2">
                    <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:gap-8 lg:gap-5">

                        <div class="space-y-2">
                            <label required for="name" class="text-body font-[500]">ឈ្មោះ <span
                                    class="text-red-500">*</span></label>
                            <input required class="input1 placeholder:text-[17px]" name="name" type="text"
                                placeholder="ឈ្មោះរបស់អ្នក" v-model="name" />
                        </div>
                        <div class="space-y-2 mt-2 lg:mt-0">
                            <label for="name" class="text-body font-[500]">ឈ្មោះក្រុមហ៊ុន <span
                                    class="text-red-500">*</span></label>
                            <input required class="input1 placeholder:text-[17px]" name="name" type="text"
                                placeholder="ឈ្មោះក្រុមហ៊ុន" v-model="companyname" />
                        </div>

                    </div>

                    <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:gap-8 lg:gap-5">
                        <div class="space-y-2">
                            <label for="name" class="text-body font-[500]">លេខទូរស័ព្ទ <span
                                    class="text-red-500">*</span></label>
                            <input required class="input1 placeholder:text-[17px]" name="name" type="text"
                                placeholder="លេខទូរស័ព្ទ" v-model="number" />
                        </div>
                        <div class="space-y-2">
                            <label for="name" class="text-body font-[500]">តេលេក្រាម</label>
                            <input class="input1 placeholder:text-[17px]" name="name" type="text"
                                placeholder="តេលេក្រាម" v-model="numbertelete" />
                        </div>
                    </div>
                    <div class="space-y-2">
                        <label for="name" class="text-body font-[500]">អាស័យដ្ឋាន</label>
                        <input class="input1 placeholder:text-[17px]" name="name" type="text" placeholder="អាស័យដ្ឋាន"
                            v-model="address" />
                        <input class="input hidden placeholder:text-[17px]" name="name" type="text"
                            placeholder="អាស័យដ្ឋាន" v-model="status" />
                        <div></div>
                    </div>
                    <label for="name" class="text-body font-[500]">សេវ៉ាកម្ម <span class="text-red-500">*</span></label>
                    <div class="">
                        <select required class="input1  w-full text-[17px] " v-model="itemsType">
                            <!-- Add the "Test" option as the first option -->
                            <!-- <option value="Test"></option> -->


                            <!-- <option v-for="option in category" :key="option">
                  {{ option.name }}
                </option> -->
                            <option disabled selected value class="placeholder-option">--ជ្រើសរើសសេវ៉ាកម្ម--</option>
                            <!-- Loop through the data array for other options -->
                            <option v-for="option in category" :key="option.id" :value="option.name"
                                class="option-item">
                                {{ option.name }}
                            </option>
                        </select>
                    </div>
                    <div class="space-y-2">
                        <label for="name" class="text-body font-[500]">គោលបំណង <span
                                class="text-red-500">*</span></label>
                        <textarea required class="input1 placeholder:text-[17px]" placeholder="គោលបំណង" name="" id=""
                            cols="30" rows="5" v-model="pospurse"></textarea>
                    </div>
                    <div>

                        <div class="flex w-full">
                            <button
                                class="md:w-[84px] md:h-[50px] h-[46px] rounded-full md:rounded-md box-btn text-borderBody xl:px-32 lg:px-32 md:px-20 w-full xl:w-[10rem] flex items-center justify-center lg:w-[10rem] py-4 mt-4">
                                ផ្ញើរ
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <Notivue v-slot="item">
        <Notification :item="item" />
    </Notivue>

    <SocialMediaVue />
</template>

<script>
import { push, Notivue, Notification } from "notivue";
import SocialMediaVue from '@/views/SocialMedia.vue'

import FooterVue from "./Footer.vue";
import NavbarVue from "./Navbar.vue";
import { ref, onMounted, onBeforeUnmount } from "vue";
import { projectFirestore } from "@/firebase/config";
import { collection, documentId, getDocs, query, where } from "firebase/firestore";
import useCollection from "@/composible/useCollection";
import { getCollectionQuery } from "@/composible/getCollection";
import { timestamp } from "@/firebase/config";
import getCollection from '@/composible/getCollections'
import { useRoute } from 'vue-router';
export default {
    props: {
        id: String,
    },
    components: {
        FooterVue,
        NavbarVue,
        Notivue,
        Notification,
        SocialMediaVue
    },
    setup(props) {
        const product = ref(null);

        // const {documents: product} = getCollection("bussiness")
        // console.log(product)

        const route = useRoute();

        console.log(product)

        onMounted(async () => {
            const q = query(collection(projectFirestore, "bussiness"), where(documentId(), "==", route.params.id));
            const querySnapshot = await getDocs(q);
            const result = []
            querySnapshot.forEach((doc) => {

                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                result.push(doc.id, doc.data())
            });

            product.value = result
            console.log(product.value)

        })


        // const fetchProduct = async () => {
        //     try {
        //         const productRef = doc(projectFirestore, "bussiness", props.id);
        //         const docSnap = await getDoc(productRef);
        //         if (docSnap.exists()) {
        //             product.value = docSnap.data();
        //             console.log(product)
        //         } else {
        //             console.error(`Product with ID ${props.id} not found.`);
        //         }

        //     } catch (error) {
        //         console.error("Error fetching product:", error);
        //     }
        // };

        // onMounted(() => {
        //     fetchProduct();
        // });
        const status = ref("Pending");
        const category = ref([]);
        const itemsType = ref("");
        const name = ref("");
        const companyname = ref("");
        const number = ref("");
        const numbertelete = ref("");
        const address = ref("");
        const pospurse = ref("");

        const { addDocs } = useCollection("inbox");
        const handleSubmit = async () => {
            try {
                const productData = {
                    status: status.value,
                    name: name.value,
                    companyname: companyname.value,
                    numbertelete: numbertelete.value,
                    itemsType: itemsType.value,
                    pospurse: pospurse.value,
                    createdAt: timestamp(),
                };

                // Add 'number' field if it has a value
                if (number.value.trim() !== "") {
                    productData.number = number.value;
                }

                // Add 'address' field if it has a value
                if (address.value.trim() !== "") {
                    productData.address = address.value;
                }

                await addDocs(productData);
                handleClear();
                push.success("អរគុណសម្រាប់ការទាក់ទង");

                console.log("Product operation successful");
            } catch (error) {
                console.error("Error performing product operation:", error);
            }
        };

        const getDataCategory = async () => {
            try {
                await getCollectionQuery(
                    "categories",
                    [],
                    (data) => {
                        category.value = data;
                    },
                    true
                );
            } catch (error) {
                console.error(error.message);
            }
        };
        onMounted(() => {
            getDataCategory();
        });
        const handleClear = () => {
            status.value = "";
            name.value = "";
            companyname.value = "";
            number.value = "";
            numbertelete.value = "";
            address.value = "";
            pospurse.value = "";
            itemsType.value = "";
        };
        return {
            // product,
            itemsType,
            category,
            name,
            companyname,
            number,
            numbertelete,
            address,
            pospurse,
            status,
            handleSubmit,
            product
        };
    },
};
</script>
<style scoped>
::-webkit-scrollbar {
    display: none;
}

p {
    line-height: 2;
}
</style>