<template>
  <div class="z-[2] top-0 left-0 ">
    <HeaderBar />
  </div>


  <div
    class="w-full xl:w-full lg:w-[100%]  bg-background flex lg:h-[90px]  duration-300 items-center shadow-sm md:shadow-md justify-center xl:h-[90px] md:h-[80px] h-[60px] sticky top-0 z-[80]">
    <div>

    </div>
    <div :class="{
      'duration-300 bg-transparent w-[1280px] flex items-center xl:justify-center mx-auto ': true
    }">
      <div class="lg:justify-between xl:hidden md:flex flex lg:flex w-full lg:w-[30%]  lg:p-4 items-center ">
        <div class="flex items-center justify-center cursor-pointer ml-4 md:ml-8">
          <svg v-motion-fade @click="SideBar" xmlns="http://www.w3.org/2000/svg"
            class="w-8 h-8 lg:ml-2 lg:hidden text_navbar" viewBox="0 0 24 24">
            <path fill="#4B5563" d="M3 18h18v-2H3zm0-5h18v-2H3zm0-7v2h18V6z" />
          </svg>


          <!-- <svg v-motion-fade @click="handleClose" v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
            fill="currentColor" class="w-8 h-8 text_navbar">
            <path
              d="M5.28 4.22a.75.75 0 0 0-1.06 1.06L6.94 8l-2.72 2.72a.75.75 0 1 0 1.06 1.06L8 9.06l2.72 2.72a.75.75 0 1 0 1.06-1.06L9.06 8l2.72-2.72a.75.75 0 0 0-1.06-1.06L8 6.94 5.28 4.22Z" />
          </svg> -->

        </div>
        <div class="flex h-full w-full cursor-pointer gap-2 items-center justify-center lg:ml-1 p-3 lg:hidden">
          <img @click="handleGogoHome"
            class="lg:w-[68px] lg:h-[68px] xl:w-[80px] md:w-[55px] md:h-[55px] w-[40px] lg:block"
            src="../assets/image/image 5.png" />
          <h1 @click="handleGogoHome" class=" font-poppins  md:text-[20px] text-primery1 lg:text-nowrap lg:text-[18px]">
            Master-IT System
          </h1>
        </div>
      </div>

      <div
        class="lg:flex xl:flex md:hidden hidden  items-center gap-[72px] lg:gap-[20px] xl:gap-[50px] mr-4 xl:px-3 lg:mr-[60px] xl:mr-0  justify-end font-NotoSansKhmer">


        <router-link class="text_navbar hover:text-primery1  font-bold py-1 relative" to="/">
          <h2 class="lg:text-[18px] xl:text-[20px]">ទំព័រដើម</h2>

          <div v-motion-fade-visible v-if="isActive('/')"
            class="bg_navbar w-full h-[4px]  lg:bottom-0 xl:bottom-0 absolute"></div>
        </router-link>



        <router-link v-if="$route.name === 'bussinessDetail'" class="text_navbar py-1 relative" to="#">
          <h2 class="lg:text-nowrap lg:text-[18px] xl:text-[20px]">ផលិតផលនិងសេវាកម</h2>
          <div v-motion-fade-visible class="bg-primery2 w-full h-[4px]  lg:bottom-0 xl:bottom-0 absolute">
          </div>
        </router-link>

        <!-- 
        <div v-else-if="$route.name === 'bms'" class="text_navbar font-bold py-1 relative">
          <div class="group relative mt-[26px]">
            <div>
              <div class="flex gap-1 items-center">
                <div
                  class="lg:text-nowrap hover:text-primery1 lg:text-[18px] xl:text-[20px] font-bold text-primary1 cursor-pointer group-hover:text-primary-dark text_navbar">
                 សេវាកម្ម
                </div>

                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-chevron-down text_navbar">
                  <path d="m6 9 6 6 6-6" />
                </svg>
              </div>

              <div class="bg-transparent cursor-pointer h-7 z-[2]"></div>
            </div>

            <div
              class="invisible opacity-0 absolute lg:w-[400px] xl:w-[585px] rounded-b-md bg-background shadow-md z-[2] top-[51px] mt-3 transition-all duration-500 ease-out transform translate-y-3 group-hover:visible group-hover:opacity-100 group-hover:translate-y-0">
              <ServiceDropdown />
            </div>
          </div>

          <div v-motion-fade-visible class="bg-black w-full h-[4px] lg:bottom-7 xl:bottom-7 absolute"></div>
        </div> -->
        <!-- 
        <div v-else-if="$route.name === 'webdesign'" class="text_navbar font-bold py-1 relative">
          <div class="group relative mt-[26px]">
            <div>
              <div class="flex gap-1 items-center">
                <div
                  class="lg:text-nowrap hover:text-primery1 lg:text-[18px] xl:text-[20px] font-bold text-primary1 cursor-pointer group-hover:text-primary-dark text_navbar">
                 សេវាកម្ម
                </div>

                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-chevron-down text_navbar">
                  <path d="m6 9 6 6 6-6" />
                </svg>
              </div>

              <div class="bg-transparent cursor-pointer h-7 z-[2]"></div>
            </div>

            <div
              class="invisible opacity-0 absolute lg:w-[400px] xl:w-[585px] rounded-b-md bg-background shadow-md z-[2] top-[51px] mt-3 transition-all duration-500 ease-out transform translate-y-3 group-hover:visible group-hover:opacity-100 group-hover:translate-y-0">
              <ServiceDropdown />
            </div>
          </div>

          <div v-motion-fade-visible class="bg-black w-full h-[4px] lg:bottom-7 xl:bottom-7 absolute"></div>
        </div>
 -->

        <!-- 
        <div v-else-if="$route.name === 'systemdev'" class="text_navbar font-bold py-1 relative">
          <div class="group relative mt-[26px]">
            <div>
              <div class="flex gap-1 items-center">
                <div
                  class="lg:text-nowrap hover:text-primery1 lg:text-[18px] xl:text-[20px] font-bold text-primary1 cursor-pointer group-hover:text-primary-dark text_navbar">
                 សេវាកម្ម
                </div>

                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-chevron-down text_navbar">
                  <path d="m6 9 6 6 6-6" />
                </svg>
              </div>

              <div class="bg-transparent cursor-pointer h-7 z-[2]"></div>
            </div>

            <div
              class="invisible opacity-0 absolute lg:w-[400px] xl:w-[585px] rounded-b-md bg-background shadow-md z-[2] top-[51px] mt-3 transition-all duration-500 ease-out transform translate-y-3 group-hover:visible group-hover:opacity-100 group-hover:translate-y-0">
              <ServiceDropdown />
            </div>
          </div>

          <div v-motion-fade-visible class="bg-black w-full h-[4px] lg:bottom-7 xl:bottom-7 absolute"></div>
        </div> -->

        <!-- <div v-else-if="$route.name === 'product'" class="text_navbar font-bold py-1 relative">
          <div class="group relative mt-[26px]">
            <div>
              <div class="flex gap-1 items-center">
                <div
                  class="lg:text-nowrap hover:text-primery1 lg:text-[18px] xl:text-[20px] font-bold text-primary1 cursor-pointer group-hover:text-primary-dark text_navbar">
                 សេវាកម្ម
                </div>

                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-chevron-down text_navbar">
                  <path d="m6 9 6 6 6-6" />
                </svg>
              </div>

              <div class="bg-transparent cursor-pointer h-7 z-[2]"></div>
            </div>

            <div
              class="invisible opacity-0 absolute lg:w-[400px] xl:w-[585px] rounded-b-md bg-background shadow-md z-[2] top-[51px] mt-3 transition-all duration-500 ease-out transform translate-y-3 group-hover:visible group-hover:opacity-100 group-hover:translate-y-0">
              <ServiceDropdown />
            </div>
          </div>

          <div v-motion-fade-visible class="bg-black w-full h-[4px] lg:bottom-7 xl:bottom-7 absolute"></div>
        </div>
 -->


        <!-- <router-link v-else-if="$route.name === 'productsandservices'" class="text_navbar font-bold py-1 relative"
          to="#">
          <div class="group relative mt-[26px]">
            <div>
              <div class="flex gap-1 items-center">
                <div
                  class="lg:text-nowrap hover:text-primery1 lg:text-[18px] xl:text-[20px] font-bold text-primary1 cursor-pointer group-hover:text-primary-dark text_navbar">
                 សេវាកម្ម
                </div>

                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-chevron-down text_navbar">
                  <path d="m6 9 6 6 6-6" />
                </svg>
              </div>

              <div class="bg-transparent cursor-pointer h-7 z-[2]"></div>
            </div>

            <div
              class="invisible opacity-0 absolute lg:w-[400px] xl:w-[785px] rounded-b-md bg-background shadow-md z-[2] top-[51px] mt-3 transition-all duration-500 ease-out transform translate-y-3 group-hover:visible group-hover:opacity-100 group-hover:translate-y-0">
              <ServiceDropdown />
            </div>
          </div>

          <div v-motion-fade-visible class="bg-black w-full h-[4px] lg:bottom-7 xl:bottom-7 absolute"></div>
        </router-link>
 -->




        <div v-else-if="$route.name === 'systemdetails'" class="text_navbar font-bold py-1 relative">
          <div class="group relative mt-[26px]">
            <div>
              <div class="flex gap-1 items-center">
                <div
                  class="lg:text-nowrap hover:text-primery1 lg:text-[18px] xl:text-[20px] font-bold text-primary1 cursor-pointer group-hover:text-primary-dark text_navbar">
                  សេវាកម្ម
                </div>

                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-chevron-down text_navbar">
                  <path d="m6 9 6 6 6-6" />
                </svg>
              </div>

              <div class="bg-transparent cursor-pointer h-7 z-[2]"></div>
            </div>

            <div
              class="invisible opacity-0  absolute lg:w-[700px] lg:-left-24 xl:-left-32  xl:w-[740px] rounded-b-md  z-[2] top-[30px] mt-3 transition-all duration-500 ease-out transform translate-y-3 group-hover:visible group-hover:opacity-100 group-hover:translate-y-0">
              <ServiceDropdown />
            </div>
          </div>

          <div v-motion-fade-visible class="bg-black w-full h-[4px] lg:bottom-7 xl:bottom-7 absolute"></div>
        </div>

        <div v-else class="group relative mt-[29px]">
          <div>
            <div class="flex gap-1 items-center ">
              <div
                class="lg:text-nowrap hover:text-primery1  lg:text-[18px] xl:text-[20px] font-bold text-primary1 cursor-pointer group-hover:text-primary-dark text_navbar">
                សេវាកម្ម
              </div>

              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="lucide lucide-chevron-down text_navbar">
                <path d="m6 9 6 6 6-6" />
              </svg>

            </div>
            <div class="bg-transparent cursor-pointer h-7  z-[2]">
            </div>
          </div>

          <div
            class="invisible opacity-0  absolute lg:w-[700px] lg:-left-24 xl:-left-32  xl:w-[740px] rounded-b-md  z-[2] top-[30px] mt-3 transition-all duration-500 ease-out transform translate-y-3 group-hover:visible group-hover:opacity-100 group-hover:translate-y-0">
            <ServiceDropdown />
          </div>
        </div>


        <router-link class="text_navbar hover:text-primery1  font-bold py-1 relative" to="/viewAllProduct">
          <h2 class="lg:text-nowrap  lg:text-[18px] xl:text-[20px]">ឧបករណ៍</h2>
          <div v-motion-fade-visible v-if="isActive('/viewAllProduct')"
            class="bg_navbar  w-full h-[4px]  lg:bottom-0 xl:bottom-[1px] absolute"></div>
        </router-link>
        <!-- 
        <router-link class="text_navbar hover:text-primery1  font-bold py-1 relative" to="/aboutUs">
          <h2 class="lg:text-nowrap  lg:text-[18px] xl:text-[20px]">អំពីពួកយើង</h2>
          <div v-motion-fade-visible v-if="isActive('/aboutUs')"
            class="bg_navbar  w-full h-[4px]  lg:bottom-0 xl:bottom-0 absolute"></div>
        </router-link> -->

        <div v-if="$route.name === 'aboutUs'" class="group relative mt-[29px]">
          <div>
            <div class="flex gap-0 items-center ">
              <div
                class="lg:text-nowrap hover:text-primery1  lg:text-[18px] xl:text-[20px] font-bold text-primary1 cursor-pointer group-hover:text-primary-dark text_navbar">
                អំពីក្រុមហ៊ុន
              </div>

              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="lucide lucide-chevron-down text_navbar">
                <path d="m6 9 6 6 6-6" />
              </svg>

            </div>
            <div class="bg-transparent cursor-pointer h-7  z-[2]">
            </div>
          </div>

          <div
            class="invisible opacity-0  absolute lg:w-[550px] lg:-left-5  xl:right-9 xl:w-[100px]   rounded-b-md  z-[2] top-[30px] mt-3 transition-all duration-500 ease-out transform translate-y-3 group-hover:visible group-hover:opacity-100 group-hover:translate-y-0">
            <AboutPageComponentVue />
          </div>
          <div v-motion-fade-visible class="bg-black w-full h-[4px] lg:bottom-6 xl:bottom-6 absolute"></div>

        </div>

        <div v-else-if="$route.name === 'visionMissionValue'" class="group relative mt-[29px]">
          <div>
            <div class="flex gap-0 items-center ">
              <div
                class="lg:text-nowrap hover:text-primery1  lg:text-[18px] xl:text-[20px] font-bold text-primary1 cursor-pointer group-hover:text-primary-dark text_navbar">
                អំពីក្រុមហ៊ុន
              </div>

              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="lucide lucide-chevron-down text_navbar">
                <path d="m6 9 6 6 6-6" />
              </svg>

            </div>
            <div class="bg-transparent cursor-pointer h-7  z-[2]">
            </div>
          </div>

          <div
            class="invisible opacity-0  absolute lg:w-[550px] lg:-left-5  xl:right-9 xl:w-[100px]   rounded-b-md  z-[2] top-[30px] mt-3 transition-all duration-500 ease-out transform translate-y-3 group-hover:visible group-hover:opacity-100 group-hover:translate-y-0">
            <AboutPageComponentVue />
          </div>
          <div v-motion-fade-visible class="bg-black w-full h-[4px] lg:bottom-6 xl:bottom-6 absolute"></div>

        </div>



        <div v-else-if="$route.name === 'actionTeamwork'" class="group relative mt-[29px]">
          <div>
            <div class="flex gap-0 items-center ">
              <div
                class="lg:text-nowrap hover:text-primery1  lg:text-[18px] xl:text-[20px] font-bold text-primary1 cursor-pointer group-hover:text-primary-dark text_navbar">
                អំពីក្រុមហ៊ុន
              </div>

              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="lucide lucide-chevron-down text_navbar">
                <path d="m6 9 6 6 6-6" />
              </svg>

            </div>
            <div class="bg-transparent cursor-pointer h-7  z-[2]">
            </div>
          </div>

          <div
            class="invisible opacity-0  absolute lg:w-[550px] lg:-left-5  xl:right-9 xl:w-[100px]   rounded-b-md  z-[2] top-[30px] mt-3 transition-all duration-500 ease-out transform translate-y-3 group-hover:visible group-hover:opacity-100 group-hover:translate-y-0">
            <AboutPageComponentVue />
          </div>
          <div v-motion-fade-visible class="bg-black w-full h-[4px] lg:bottom-6 xl:bottom-6 absolute"></div>

        </div>


        <div v-else class="group relative mt-[29px]">
          <div>
            <div class="flex gap-0 items-center ">
              <div
                class="lg:text-nowrap hover:text-primery1  lg:text-[18px] xl:text-[20px] font-bold text-primary1 cursor-pointer group-hover:text-primary-dark text_navbar">
                អំពីក្រុមហ៊ុន
              </div>

              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="lucide lucide-chevron-down text_navbar">
                <path d="m6 9 6 6 6-6" />
              </svg>

            </div>
            <div class="bg-transparent cursor-pointer h-7  z-[2]">
            </div>
          </div>

          <div
            class="invisible opacity-0  absolute lg:w-[500px] lg:-left-5  xl:right-9  xl:w-[100px] rounded-b-md  z-[2] top-[30px] mt-3 transition-all duration-500 ease-out transform translate-y-3 group-hover:visible group-hover:opacity-100 group-hover:translate-y-0">
            <AboutPageComponentVue />
          </div>

        </div>


        <router-link class="text_navbar hover:text-primery1  font-bold py-1 relative" to="/contact">
          <h2 class="lg:text-nowrap lg:text-[18px] xl:text-[20px]">ទំនាក់ទំនង</h2>
          <div v-motion-fade-visible v-if="isActive('/contact')"
            class="bg_navbar  w-full h-[4px]  lg:bottom-0 xl:bottom-0 absolute"></div>
        </router-link>
      </div>
    </div>

    <Transition>
      <div class="item" v-if="isOpenMenuMobile">
        <!-- <MobileSidebarVue handleCloseMenu="handleCloseMenu" /> -->
        <component :is="currentComponent" @close="handleClose" />
      </div>
    </Transition>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import MobileSidebarVue from "./MobileSidebar.vue";
import ServiceDropdown from '@/components/ServicedropDownComponent.vue'
import { getCollectionQuery } from "@/composible/getCollection";
import getCollection from "@/composible/getCollections";
import HeaderBar from "./HeaderBar.vue";
import AboutPageComponentVue from '@/components/AboutPageComponent.vue'

export default {
  components: {
    MobileSidebarVue,
    ServiceDropdown,
    HeaderBar,
    AboutPageComponentVue
  },
  setup() {

    const category = ref([]);
    const nav_item = ref([
      { link: "/", names: "ទំព័រដើម" },
      { link: "/aboutUs", names: "អំពីពួកយើង" },
      { link: "/productsandservices", names: "សេវ៉ាកម្ម" },
      { link: "/contact", names: "ទំនាក់ទំនង" },
    ]);

    const { documents: hardwareCategory } = getCollection('hardwareCategories')


    const handleCloseMenu = ref(false)

    const isScrolled = ref(false);
    const isOpenMenuMobile = ref(false); // Initially set to false
    const currentComponent = ref("");
    const router = useRouter();




    const getData = async () => {
      try {
        await getCollectionQuery(
          "categories",
          [],
          (data) => {
            const truncatedData = data.map((item) => ({
              ...item,
            }));
            category.value = truncatedData;
          },
          true
        );
      } catch (error) {
        console.error(error.message);
      }
    };

    onMounted(() => {
      getData();
    });

    const SideBar = () => {
      currentComponent.value = "MobileSidebarVue";
      isOpenMenuMobile.value = !isOpenMenuMobile.value;
      toggleScroll(isOpenMenuMobile.value);
    };

    const handleGogoHome = () => {
      router.push({ name: "mainpage" });
    };

    const handleScroll = () => {
      isScrolled.value = window.scrollY > 0;
    };

    const isActive = (path) => {
      return router.currentRoute.value.path === path;
    };

    const handleClose = () => {
      currentComponent.value = "";
      isOpenMenuMobile.value = !isOpenMenuMobile.value;
      toggleScroll(isOpenMenuMobile.value);
    };

    //disable scroll
    const disableScroll = () => {
      document.body.style.overflow = 'hidden';
    };


    const enableScroll = () => {
      document.body.style.overflow = '';
    };

    const toggleScroll = (disable) => {
      if (disable) {
        disableScroll();
      } else {
        enableScroll();
      }
    };

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
      enableScroll();
    });

    return {
      isOpenMenuMobile,
      isScrolled,
      handleGogoHome,
      SideBar,
      currentComponent,
      handleClose,
      nav_item,
      isActive,
      handleCloseMenu,
      category,
      hardwareCategory
    };
  },
};
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

::-webkit-scrollbar {
  display: none;
}

* {
  scrollbar-width: none;
}

/* .router-link-active {
  border-bottom: 3px #500192 solid;
} */
</style>
