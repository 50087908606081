<template>

    <div v-motion :initial="{
        opacity: 0,
    }" :enter="{

        opacity: 1,
        transition: {
            duration: 500,

            ease: 'easeIn',
        },
    }" class="flex flex-col bg-background px-2 md:px-0">
        <div class="w-full md:w-[90%] xl:w-[1280px] lg:w-[90%] mx-auto space-y-4 relative">
            <div class=" lg:mt-8 xl:mt-8 md:mt-3 mt-4 text-start lg:p-0 xl:p-0 xl:px-4">
                <h2
                    class="text-2xl mt-5 font-NotoSansKhmer font-bold md:mt-8 md:text-3xl md:text-start  lg:text-2xl xl:text-3xl text-primery1 lg:text-start xl:text-start text-center xl:ml-0  ">
                    រចនាគេហទំព័រ
                </h2>
            </div>

            <div
                class=" w-full xl:w-[1280px] xl:px-4 md:py-0 lg:p-0 xl:p-0  mx-auto border-b-gray-400 lg:h-auto xl:auto md:h-auto h-auto lg:flex lg:flex-row xl:flex xl:flex-row md:flex md:flex-col-reverse flex flex-col-reverse items-center justify-between">
                <div class="flex md:w-[] lg:w-[60%] "> 
                    <div v-for="categories in category" :key="categories"
                        class="text-[14px] mx-4  md:mx-0 text-textbody md:text-[18px] lg:text-[14px] xl:text-[18px] font-[500]">
                        <span
                            class=" text-justify text-black/70 font-NotoSansKhmer prose-li:text-wrap sm:prose-li:text-wrap text-[16px]   md:mx-0  md:text-[18px] lg:text-[16px] xl:text-[18px] font-[500]"
                            v-html="categories.description"></span>
                    </div>

                </div>
            </div>


            <div class="grid grid-cols-1  md:px-0 px-4 sm:grid-cols-2 md:grid-cols-3 gap-5 lg:gap-10 xl:px-4">
                <div v-for="item in dataitem" :key="item.id">
                    <div
            class="bg-white  hover:-translate-y-4 transition-all duration-300 ease-in-out border-[1px] h-full   md:h-full   xl:h-full flex flex-col justify-between">

            <div class="mt-2  xl:flex  gap-5 xl:space-y-0 lg:space-y-4 xl:mt-0 ">

              <div>
                <div class="-mt-2 xl:mt-0 flex justify-center">
                  <!-- <img :src="item.image"
                                        class="w-[400px] h-[300px]   md:w-[420px] md:h-[300px] lg:w-[350px] lg:h-[250px] xl:w-[400px] xl:h-[300px]"
                                        alt=""> -->
                  <img
                    class="w-[400px] h-[300px] border  md:w-[300px] md:h-[200px] lg:w-[350px] lg:h-[250px] xl:w-[400px] xl:h-[300px]"
                    src="https://fakeimg.pl/400x300/" alt="Product Image" />
                </div>
                <div class="p-4 space-y-2">
                  <h4
                    class="text-[18px] mt-3 xl:mt-0 font-poppins text-black/70 font-bold md:text-[16px] xl:text-[18px] lg:text-[18px] line-clamp-2">
                    {{ item.name }}</h4>
                  <div v-html="item.descritpts"
                    class="text-[15px] mt-2 text-justify  md:text-[15px] line-clamp-4  xl:text-[16px] sm:line-clamp-4 text-[#909090] xl:mt-3 md:line-clamp-3 lg:line-clamp-4 xl:line-clamp-3 lg:text-[16px] ">
                  </div>

                  <div class="flex justify-end xl:justify-end  mt-3">

                    <router-link :to="{ name: 'systemdetails', params: { id: item.id } }"
                      class="text-primery1/90 text-[17px] hover:text-indigo-600  md:mt-[5px] flex items-center gap-1 rounded-md  md:text-[14px] lg:text-[16px] xl:text-[18px] font-NotoSansKhmer font-bold grow_skew_forward relative z-10  text-2xl py-1 lg:py-1.5 px-8 mt-1   hover:border-blue-500">
                      <p>មើលបន្ថែម</p>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                      </svg>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>

          </div>
                </div>
            </div>
        </div>
        <div class="w-full h-[48px]"></div>
    </div>
</template>



<script>
import { getCollectionQuery } from "@/composible/getCollection";
import { ref, onMounted } from "vue";
import Navbar from "./Navbar.vue";
export default {
    components: {
        Navbar
    },
    setup() {
        const dataitem = ref([]);
        const getData = async () => {
            try {
                await getCollectionQuery(
                    "products",
                    [],
                    (data) => {
                        dataitem.value = data.filter(
                            (item) => item.type === "Web Design"
                        );
                    },
                    true
                );
            } catch (error) {
                console.error(error.message);
            }
        };
        const category = ref([]);

        const getDataCategory = async () => {
            try {
                await getCollectionQuery(
                    "categories",
                    [],
                    (data) => {
                        category.value = data.filter(
                            (item) => item.name === "Web Design"
                        );
                    },
                    true
                );
            } catch (error) {
                console.error(error.message);
            }
        };
        onMounted(() => {
            getData();
            getDataCategory();
        });
        return { dataitem, category };
    },
};
</script>
<style scoped>
::-webkit-scrollbar {
    display: none;
}
</style>