<template>

  <div class="bg-gray-100 h-full font-NotoSansKhmer w-[64rem] md:w-full">
    <div class=" h-full w-[80rem] md:w-[125%] bg-gray-100 lg:w-[130%] xl:w-[120rem]">
      <div class="flex h-screen w-full">
        <div
          :class="isOpenSidebar ? 'xl:w-[390px] lg:w-[20%] md:w-[10%] transition-all duration-300  ' : 'xl:w-[6rem]  lg:w-[5rem] md:w-[5rem]  transition-all duration-300'"
          class="bg-white/50 my-4  ">
          <div v-if="userDocument" class="">
            <div class="mt-2">
              <div :class="isOpenSidebar ? 'ml-2' : 'justify-center'"
                class="lg:items-center lg:flex  p-2 justify-start">

                <div @click="isOpenSidebar = !isOpenSidebar" class=" cursor-pointer hidden lg:flex">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="size-8 hover:text-gray-800 transition-all ease-in-out"
                    :class="isOpenSidebar ? 'lg:ml-1 transition-all duration-300' : 'transition-all duration-300'">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                  </svg>

                </div>
                <div :class="isOpenSidebar ? 'lg:flex  ' : 'hidden '" class=" flex justify-center">
                  <img class="md:w-14 md:object-contain  md:h-14 h-12 w-12 hidden md:hidden  lg:hidden xl:hidden "
                    src="../assets/image/image 5.png" alt="" />
                  <div
                    class="font-bold md:ml-0 hidden lg:block lg:text-lg xl:block xl:text-xl lg:text-primery1 xl:text-nowrap lg:text-nowrap xl:ml-2 lg:ml-2 md:text-wrap">
                    ម៉ាស្ទ័រអាយធី<span class="text-transparent">_</span>ស៊ីស្ទីម</div>

                </div>
                <div class="lg:hidden  mt-2 flex justify-center">
                  <div>
                    <img class="md:w-14  md:object-contain  md:h-14 h-12 w-12  lg:hidden xl:hidden "
                      src="../assets/image/image 5.png" alt="" />
                    <p class="flex-wrap font-bold mt-2 text-center">ម៉ាស្ទ័រ</p>
                    <p class="flex-wrap font-bold text-center">អាយធី</p>
                    <p class="font-bold text-center">ស៊ីស្ទីម</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-3 space-y-1">
              <div class="">
                <router-link to="/admin"
                  :class="isOpenSidebar ? 'lg:pl-2' : 'xl:justify-center lg:justify-center md:justify-center'"
                  class="text-body flex space-x-2 text-nowrap  text-primery1 md:p-4 p-4 font-semibold  md:justify-center  py-[12px] lg:justify-normal    hover:text-indigo-300   duration-300">

                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="lucide lucide-circle-gauge">
                    <path d="M15.6 2.7a10 10 0 1 0 5.7 5.7" />
                    <circle cx="12" cy="12" r="2" />
                    <path d="M13.4 10.6 19 5" />
                  </svg>
                  <p class="hidden " :class="isOpenSidebar ? 'lg:flex hidden' : 'hidden'">ទំព័រដើម</p>
                </router-link>
              </div>
              <div>

                <router-link
                  :class="isOpenSidebar ? 'lg:pl-2' : 'xl:justify-center lg:justify-center md:justify-center'"
                  class="text-body flex space-x-2 text-nowrap  text-primery1 font-semibold md:p-4 p-4 md:justify-center  py-[12px] lg:justify-normal    hover:text-indigo-300   duration-300"
                  to="/services">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="lucide lucide-boxes">
                    <path
                      d="M2.97 12.92A2 2 0 0 0 2 14.63v3.24a2 2 0 0 0 .97 1.71l3 1.8a2 2 0 0 0 2.06 0L12 19v-5.5l-5-3-4.03 2.42Z" />
                    <path d="m7 16.5-4.74-2.85" />
                    <path d="m7 16.5 5-3" />
                    <path d="M7 16.5v5.17" />
                    <path
                      d="M12 13.5V19l3.97 2.38a2 2 0 0 0 2.06 0l3-1.8a2 2 0 0 0 .97-1.71v-3.24a2 2 0 0 0-.97-1.71L17 10.5l-5 3Z" />
                    <path d="m17 16.5-5-3" />
                    <path d="m17 16.5 4.74-2.85" />
                    <path d="M17 16.5v5.17" />
                    <path
                      d="M7.97 4.42A2 2 0 0 0 7 6.13v4.37l5 3 5-3V6.13a2 2 0 0 0-.97-1.71l-3-1.8a2 2 0 0 0-2.06 0l-3 1.8Z" />
                    <path d="M12 8 7.26 5.15" />
                    <path d="m12 8 4.74-2.85" />
                    <path d="M12 13.5V8" />
                  </svg>
                  <p class="hidden" :class="isOpenSidebar ? 'hidden lg:flex' : 'hidden transition-all '">ប្រភេទសេវាកម្ម
                  </p>
                </router-link>
              </div>

              <div>
                <router-link
                  :class="isOpenSidebar ? 'lg:pl-2' : 'xl:justify-center lg:justify-center md:justify-center'"
                  class="text-body flex space-x-2 text-nowrap  text-primery1 font-semibold md:p-3 p-4  md:justify-center  py-[12px] lg:justify-normal    hover:text-indigo-300   duration-300"
                  to="/banner">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="lucide lucide-picture-in-picture-2">
                    <path d="M21 9V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v10c0 1.1.9 2 2 2h4" />
                    <rect width="10" height="7" x="12" y="13" rx="2" />
                  </svg>

                  <p class="hidden" :class="isOpenSidebar ? 'hidden lg:flex' : 'hidden'">ផ្ទាំងបង្ហាញ
                  </p>
                </router-link>
              </div>

              <div>
                <router-link
                  :class="isOpenSidebar ? 'lg:pl-2' : 'xl:justify-center lg:justify-center md:justify-center'"
                  class="text-body flex space-x-2 text-nowrap  text-primery1 font-semibold md:p-3 p-4  md:justify-center  py-[12px] lg:justify-normal    hover:text-indigo-300   duration-300"
                  to="/hardwareCategory">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="size-[24px]">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25" />
                  </svg>

                  <p class="hidden" :class="isOpenSidebar ? 'hidden lg:flex' : 'hidden'">ប្រភេទឧបករណ៍
                  </p>
                </router-link>
              </div>


              <div>
                <router-link
                  :class="isOpenSidebar ? 'lg:pl-2' : 'xl:justify-center lg:justify-center md:justify-center'"
                  class="text-body flex space-x-2 text-nowrap  text-primery1 font-semibold md:p-3 p-4  md:justify-center  py-[12px] lg:justify-normal    hover:text-indigo-300   duration-300"
                  to="/products">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="lucide lucide-archive">
                    <rect width="20" height="5" x="2" y="3" rx="1" />
                    <path d="M4 8v11a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8" />
                    <path d="M10 12h4" />
                  </svg>
                  <p class="hidden" :class="isOpenSidebar ? 'hidden lg:flex' : 'hidden'">ផលិតផល</p>
                </router-link>
              </div>


              <div>
                <router-link
                  :class="isOpenSidebar ? 'lg:pl-2' : 'xl:justify-center lg:justify-center md:justify-center'"
                  class="text-body flex space-x-2 text-nowrap  text-primery1 font-semibold md:p-3 p-4 md:justify-center  py-[12px] lg:justify-normal    hover:text-indigo-300   duration-300"
                  to="/listproduct">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
                  </svg>

                  <p class="hidden" :class="isOpenSidebar ? 'hidden lg:flex' : 'hidden'">ឧបករណ៍
                  </p>
                </router-link>
              </div>

              <div v-if="userDocument.role == 'SuperAdmin'">
                <router-link
                  :class="isOpenSidebar ? 'lg:pl-2' : 'xl:justify-center lg:justify-center md:justify-center'"
                  class="text-body flex space-x-2 text-nowrap  text-primery1 font-semibold md:p-3 p-4 md:justify-center  py-[12px] lg:justify-normal    hover:text-indigo-300   duration-300"
                  to="/adminmanager">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="lucide lucide-users">
                    <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
                    <circle cx="9" cy="7" r="4" />
                    <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                  </svg>
                  <p class="hidden" :class="isOpenSidebar ? 'hidden lg:flex' : 'hidden'">
                    អ្នកប្រើប្រាស់</p>
                </router-link>
              </div>

              <div>
                <router-link
                  :class="isOpenSidebar ? 'lg:pl-2' : 'xl:justify-center lg:justify-center md:justify-center'"
                  class="text-body flex space-x-2 text-nowrap  text-primery1 font-semibold md:p-3 p-4 md:justify-center  py-[12px] lg:justify-normal    hover:text-indigo-300   duration-300"
                  to="/qanda">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="lucide lucide-circle-help">
                    <circle cx="12" cy="12" r="10" />
                    <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                    <path d="M12 17h.01" />
                  </svg>
                  <p class="hidden" :class="isOpenSidebar ? 'hidden lg:flex' : 'hidden'">សំណួរខ្លី</p>
                </router-link>
              </div>
              <div>
                <router-link
                  :class="isOpenSidebar ? 'lg:pl-2' : 'xl:justify-center lg:justify-center md:justify-center'"
                  class="text-body flex space-x-2 text-nowrap  text-primery1 font-semibold md:p-3 p-4 md:justify-center  py-[12px] lg:justify-normal    hover:text-indigo-300   duration-300"
                  to="/partner">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="lucide lucide-handshake">
                    <path d="m11 17 2 2a1 1 0 1 0 3-3" />
                    <path
                      d="m14 14 2.5 2.5a1 1 0 1 0 3-3l-3.88-3.88a3 3 0 0 0-4.24 0l-.88.88a1 1 0 1 1-3-3l2.81-2.81a5.79 5.79 0 0 1 7.06-.87l.47.28a2 2 0 0 0 1.42.25L21 4" />
                    <path d="m21 3 1 11h-2" />
                    <path d="M3 3 2 14l6.5 6.5a1 1 0 1 0 3-3" />
                    <path d="M3 4h8" />
                  </svg>
                  <p class="hidden" :class="isOpenSidebar ? 'hidden lg:flex' : 'hidden'">ដៃគូរសហការណ៍
                  </p>
                </router-link>
              </div>
              <div>
                <router-link
                  :class="isOpenSidebar ? 'lg:pl-2' : 'xl:justify-center lg:justify-center md:justify-center'"
                  class="text-body flex space-x-2 text-nowrap  text-primery1 font-semibold md:p-3 p-4 md:justify-center  py-[12px] lg:justify-normal    hover:text-indigo-300   duration-300"
                  to="/inbox">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="lucide lucide-package-open">
                    <path d="M12 22v-9" />
                    <path
                      d="M15.17 2.21a1.67 1.67 0 0 1 1.63 0L21 4.57a1.93 1.93 0 0 1 0 3.36L8.82 14.79a1.655 1.655 0 0 1-1.64 0L3 12.43a1.93 1.93 0 0 1 0-3.36z" />
                    <path
                      d="M20 13v3.87a2.06 2.06 0 0 1-1.11 1.83l-6 3.08a1.93 1.93 0 0 1-1.78 0l-6-3.08A2.06 2.06 0 0 1 4 16.87V13" />
                    <path
                      d="M21 12.43a1.93 1.93 0 0 0 0-3.36L8.83 2.2a1.64 1.64 0 0 0-1.63 0L3 4.57a1.93 1.93 0 0 0 0 3.36l12.18 6.86a1.636 1.636 0 0 0 1.63 0z" />
                  </svg>
                  <p class="hidden" :class="isOpenSidebar ? 'hidden lg:flex' : 'hidden'">ប្រអប់សារ</p>
                </router-link>
              </div>
            </div>

          </div>
        </div>



        <div class="mx-4 my-4 w-[100%] md:w-[100%] lg:w-[130%] ">

          <NavbarAnswerAndQuestionVue />

          <div class="w-full  lg:h-[90%] xl:h-[90%] md:h-[60%]">
            <div class="my-4">
              <button @click="isAdd"
                class="px-6 py-2 group hover:bg-indigo-800 bg-primery1 flex items-center gap-2 text-white text-body  font-semibold">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                  class="w-[20px] h-[30px] text-white group-hover:text-background">
                  <path
                    d="M10.75 4.75a.75.75 0 0 0-1.5 0v4.5h-4.5a.75.75 0 0 0 0 1.5h4.5v4.5a.75.75 0 0 0 1.5 0v-4.5h4.5a.75.75 0 0 0 0-1.5h-4.5v-4.5Z" />
                </svg>

                បន្ថែមថ្មី
              </button>
            </div>

            <div class="w-full  p-3 flex items-center justify-between bg-white/50">

              <div>
                <vs-select width="100px" @change="updatePagination" v-model="itemsPerPage">
                  <vs-select-item v-for="(option, index) in itemsPerPageOptions" :key="index" :modelValue="option"
                    :text="option" />
                </vs-select>
              </div>

              <div class="flex items-center gap-2">
                <div class="flex items-center px-2 relative">
                  <input placeholder="ស្វែងរកតាមឈ្មោះ"
                    class="w-full p-2 border-[1px] hover:border-primery1 bg-white/50 px-5 outline-none" type="text"
                    name="" id="" v-model="searchQuery" />
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"
                    class="w-7 h-7 absolute right-4">
                    <path fill-rule="evenodd"
                      d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                      clip-rule="evenodd" />
                  </svg>
                </div>
                <Menu as="div" class="relative inline-block text-left">
                  <div>
                    <MenuButton
                      class="inline-flex w-full justify-center rounded-md text-sm font-medium text-white hover:bg-black/30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
                      <button
                        class="p-3 group bg-background flex items-center gap-2 border-[1px] hover:bg-gray-50 hover:border-primery1 text-body  font-semibold">
                        <font-awesome-icon icon="filter" class=" text-primery1 " />
                      </button>
                    </MenuButton>
                  </div>

                  <transition enter-active-class="transition duration-100 ease-out"
                    enter-from-class="transform scale-95 opacity-0" enter-to-class="transform scale-100 opacity-100"
                    leave-active-class="transition duration-75 ease-in"
                    leave-from-class="transform scale-100 opacity-100" leave-to-class="transform scale-95 opacity-0">
                    <MenuItems
                      class="absolute z-40 right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white ring-1 ring-black/5 focus:outline-none">
                      <div class="px-1 py-1">
                        <MenuItem v-slot="{ active }">
                        <button @click="sortList('name')" :class="[
                          active ? 'bg-primery1 text-white' : 'text-gray-900',
                          'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                        ]">
                          <EditIcon :active="active" class="mr-2 h-5 w-5 text-violet-400" aria-hidden="true" />
                          ច្រោះតាមឈ្មោះ A-Z
                        </button>
                        </MenuItem>
                        <MenuItem v-slot="{ active }">
                        <button @click="sortList('createdAt')" :class="[
                          active ? 'bg-primery1 text-white' : 'text-gray-900',
                          'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                        ]">
                          <DuplicateIcon :active="active" class="mr-2 h-5 w-5 text-violet-400" aria-hidden="true" />
                          ច្រោះតាមថ្ងៃខែ
                        </button>
                        </MenuItem>
                      </div>
                    </MenuItems>
                  </transition>
                </Menu>
              </div>
            </div>
            <!-- <div class="w-full border border-black mx-auto"></div> -->
            <div class="w-full mx-auto bg-white/50 p-3 mt-4">
              <!-- partner -->

              <div
                :class="isOpenSidebar ? 'lg:w-full xl:w-[100rem] transition-all duration-300 ease-in-out' : 'xl:w-[110rem] transition-all duration-300 ease-in-out'"
                class=" h-[500px] overflow-auto mt-4">
                <div v-if="currentPageItems.length > 0" class="relative overflow-x-auto">
                  <div v-if="currentPageItems.length > 0"
                    class="relative md:w-fulllg:w-full h-[500px] overflow-y-auto overflow-x-auto overflow-hidden">
                    <table class="w-full text-sm text-left   rtl:text-right text-gray-500 dark:text-gray-400">
                      <thead class=" text-gray-700 uppercase bg-gray-200/30 dark:bg-gray-700  dark:text-gray-400">
                        <tr class="text-center  border-2">

                          <th class="text-center text-[17px] px-5 p-5">ល.រ</th>
                          <th class="px-10 text-[17px]">
                            ថ្ងៃបញ្ចូល
                          </th>
                          <th class="text-[17px] px-10">សំណួរ</th>
                          <th class="text-[17px] px-10">ចម្លើយ</th>
                          <th class="text-[17px]  px-6">សកម្មភាព</th>
                        </tr>
                      </thead>
                      <tbody>

                        <tr v-for="(data, index) in currentPageItems" :key="index" class="overflow-hidden">
                          <td class="text-center w-10">
                            {{ index + 1 }}
                          </td>
                          <td class=" w-10">
                            {{
                              data && data.createdAt
                                ? new Date(
                                  data.createdAt.seconds * 1000
                                ).toLocaleString()
                            : "N/A"
                            }}
                          </td>


                          <td class="px-6 w-24">
                            <p class="line-clamp-2 capitalize"> {{ data.question }}</p>
                          </td>

                          <td class="w-44">
                            <p class="line-clamp-3 capitalize prose prose-p:line-clamp-1" v-html="data.answer"></p>
                          </td>
                          <td class="w-10">
                            <div class="flex w-full h-12 gap-3 items-center justify-center">
                              <button @click="handleAddEditData(data)"
                                class="w-[36px] flex items-center justify-center h-[36px] bg-accent rounded-[6px]">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                  class="w-6 h-6 text-white">
                                  <path
                                    d="m5.433 13.917 1.262-3.155A4 4 0 0 1 7.58 9.42l6.92-6.918a2.121 2.121 0 0 1 3 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 0 1-.65-.65Z" />
                                  <path
                                    d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0 0 10 3H4.75A2.75 2.75 0 0 0 2 5.75v9.5A2.75 2.75 0 0 0 4.75 18h9.5A2.75 2.75 0 0 0 17 15.25V10a.75.75 0 0 0-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5Z" />
                                </svg>
                              </button>
                              <button @click="handleDelete(data.id)"
                                class="w-[36px] flex items-center justify-center h-[36px] bg-red-500 rounded-[6px]">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                  class="w-6 h-6 text-white">
                                  <path fill-rule="evenodd"
                                    d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z"
                                    clip-rule="evenodd" />
                                </svg>
                              </button>
                            </div>
                          </td>
                        </tr>

                      </tbody>

                      <div v-if="isDeleteOpen == true"
                        class="fixed w-full h-full z-[30] bg-black/30 flex items-center top-0 right-0 justify-center">
                        <div v-motion-slide-top class="w-[400px] bg-background p-3  xl:mb-0 -mt-[40rem]">
                          <h2 class="text-body text-primery1 text-xl">
                            តើអ្នកពិតជាចង់លុបមែនឬ?
                          </h2>
                          <div class="flex items-center justify-end py-4 gap-2">
                            <button
                              class="px-5 p-2 text-white bg-accent transition-all duration-300 ease-in-out hover:bg-yellow-500"
                              @click="closeDelete">
                              បោះបង់
                            </button>
                            <button @click="deleteProduct"
                              class="px-5 p-2 text-white  transition-all duration-300 ease-in-out hover:bg-blue-500 bg-primery1 hover:text-white">
                              លុប
                            </button>
                          </div>
                        </div>
                      </div>
                    </table>
                  </div>
                  <div v-else class="relative sm:rounded-lg flex items-center justify-center h-[400px]">
                    <h2 class="text-heading3 text-primery1">មិនមានទិន្ន័យ</h2>
                  </div>
                  <div class="w-full flex items-center justify-end mt-4 gap-4">

                    <button :class="{ 'bg-primery1/50': currentPage === 1 }" v-if="currentPage > 1"
                      class="w-10 h-10 bg-primery1 rounded-md text-white flex items-center justify-center hover:bg-background hover:border-2 duration-200 hover:text-primery1 hover:border-primery1"
                      @click="prevPage">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-6 h-6">
                        <path fill-rule="evenodd"
                          d="M14 8a.75.75 0 0 1-.75.75H4.56l3.22 3.22a.75.75 0 1 1-1.06 1.06l-4.5-4.5a.75.75 0 0 1 0-1.06l4.5-4.5a.75.75 0 0 1 1.06 1.06L4.56 7.25h8.69A.75.75 0 0 1 14 8Z"
                          clip-rule="evenodd" />
                      </svg>
                    </button>
                    {{ currentPage }} នៃ {{ totalPages }}
                    <button :class="{ 'bg-primery1/50': currentPage === totalPages }"
                      class="w-10 h-10 bg-primery1 rounded-md text-white flex items-center justify-center hover:bg-background hover:border-2 duration-200 hover:text-primery1 hover:border-primery1"
                      @click="nextPage">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-6 h-6">
                        <path fill-rule="evenodd"
                          d="M2 8a.75.75 0 0 1 .75-.75h8.69L8.22 4.03a.75.75 0 0 1 1.06-1.06l4.5 4.5a.75.75 0 0 1 0 1.06l-4.5 4.5a.75.75 0 0 1-1.06-1.06l3.22-3.22H2.75A.75.75 0 0 1 2 8Z"
                          clip-rule="evenodd" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>

        </div>

      </div>
    </div>




    <Notivue v-slot="item">
      <Notification :item="item" />
    </Notivue>
    <component :is="currentComponent" @close="handleClose" :datatoedit="datatoedit" @AddSusccesfully="handleSubmit"
      @UpddateSuccess="handleUpdateSuccess" />
  </div>
  <div>

  </div>
</template>

<script>
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import Footer from "./Footer.vue";
import Navbar from "./Navbar.vue";
import { onMounted, ref, computed } from "vue";
import AddQandAVue from "./AddQandA.vue";
import { push, Notivue, Notification } from "notivue";
import useCollection from "@/composible/useCollection";
import { getCollectionQuery } from "@/composible/getCollection";
import { getAuth } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { projectFirestore } from "@/firebase/config";
import NavbarAnswerAndQuestionVue from '@/titleAdmin/NavbarAnswerAndQuestion.vue'
export default {
  components: {
    Navbar,
    AddQandAVue,
    Notivue,
    Notification,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    Footer,
    NavbarAnswerAndQuestionVue
  },
  setup() {

    const isOpenSidebar = ref(true);
    const sortedbyASC = ref(true);
    const userDocument = ref(null);
    const dataitem = ref([]);
    const datatoedit = ref(null);
    const productId = ref(null);
    const searchQuery = ref("");
    const currentComponent = ref("");
    const isDeleteOpen = ref(false);
    const itemsPerPage = ref(20);
    const currentPage = ref(1);
    const itemsPerPageOptions = ref([20, 30, 40, 50]);
    const { removeDoc } = useCollection("FAQ");

    onMounted(() => {
      getData();
      fetchUserData();
    });

    //filter search
    const filteredItems = computed(() => {
      const query = searchQuery.value.toLowerCase();
      return dataitem.value.filter((item) => {
        if ((item && item.answer) || item.question) {
          return (
            item.answer.toLowerCase().includes(query) ||
            item.question.toLowerCase().includes(query)
          );
        }
        return false;
      });
    });


    const totalPages = computed(() => {
      const totalItems = filteredItems.value.length;
      const itemsPerPage = 20;
      return Math.ceil(totalItems / itemsPerPage);
    });

    const currentPageItems = computed(() => {
      const startIndex = (currentPage.value - 1) * itemsPerPage.value;
      const endIndex = startIndex + itemsPerPage.value;
      return filteredItems.value.slice(startIndex, endIndex);
    });

    const getData = async () => {
      try {
        const data = await getCollectionQuery(
          "FAQ",
          [],
          (data) => {
            dataitem.value = data;
          },
          true
        );
      } catch (error) {
        console.error(error.message);
      }
    };

    const sortList = (sortBy) => {
      if (sortedbyASC.value) {
        dataitem.value.sort((x, y) => (x[sortBy] > y[sortBy] ? -1 : 1));
        sortedbyASC.value = false;
      } else {
        dataitem.value.sort((x, y) => (x[sortBy] < y[sortBy] ? -1 : 1));
        sortedbyASC.value = true;
      }
    };

    const fetchUserData = async () => {
      try {
        const userlogin = getAuth().currentUser;
        if (userlogin) {
          if (!userDocument.value) {
            const docRef = doc(projectFirestore, "users", userlogin.uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              userDocument.value = docSnap.data();
            }
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error.message);
      }
    };

    const updatePagination = () => {
      currentPage.value = 1;
    };



    //pre paginate
    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value -= 1;
      }
    };

    //next paginate
    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value += 1;
      }
    };

    const handleSuccess = (message) => {
      push.success(message);
    };
    const handleSubmit = () => {
      handleSuccess("បន្ថែមបានដោយជោគជ័យ");
    };

    const handleUpdateSuccess = () => {
      handleSuccess("អាប់ដេតភាពជោគជ័យ");
    };


    const handleAddEditData = (item) => {
      currentComponent.value = "AddQandAVue";
      datatoedit.value = item;
      console.log("====================================");
      console.log(datatoedit.value);
      console.log("====================================");
    };

    const handleClose = () => {
      currentComponent.value = "";
    };

    const isAdd = () => {
      currentComponent.value = "AddQandAVue";
      datatoedit.value = null;
    };

    const closeDelete = () => {
      isDeleteOpen.value = !isDeleteOpen.value;
    };

    const handleDelete = (id) => {
      productId.value = id;
      isDeleteOpen.value = !isDeleteOpen.value;
    };

    const deleteProduct = async () => {
      closeDelete();
      try {
        if (!productId.value) {
          console.error("Product ID is required.");
          return;
        }
        await removeDoc(productId.value);
        push.success("បានលុបដោយជោគជ័យ");

        console.log(productId.value);
        productId.value = "";
        console.log("Product deleted successfully");
      } catch (error) {
        console.error("Error deleting product:", error);
      }
    };
    return {
      currentComponent,
      isAdd,
      handleClose,
      dataitem,
      handleAddEditData,
      datatoedit,
      handleSubmit,
      handleUpdateSuccess,
      deleteProduct,
      handleDelete,
      isDeleteOpen,
      closeDelete,
      sortList,
      currentPageItems,
      currentPage,
      totalPages,
      prevPage,
      nextPage,
      filteredItems,
      searchQuery,
      itemsPerPage,
      itemsPerPageOptions,
      updatePagination,
      userDocument,
      isOpenSidebar
    };
  },
};
</script>
<style scoped>
.router-link-active {
  background-color: #500192;
  width: 100%;


  /* border-radius: 6px; */

  color: white;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #510192b9;
  border-radius: 10px;
  margin-left: 20px;
  transition: 3s;
  display: none;
}

::-webkit-scrollbar-thumb:hover {
  display: block;
  transition: 0.5s;
}

/* horizon scroll disable */
::-webkit-scrollbar-thumb:horizontal {
  background: #510192b9;
  border-radius: 10px;
  transition: 3s;
  display: none;
}

/* line climp */
.lines {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  height: 120px;
  overflow: hidden;
  font-size: 15px;
}

table,
tr,
th,
td {
  border: 1px solid lightgray;
  border-collapse: collapse;
}
</style>
