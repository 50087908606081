<template>
    <div class="flex flex-col items-center justify-center w-full h-screen fixed top-0 right-0 bg-black/20 z-[20]">
        <div v-motion-slide-top class="w-full overflow-y-auto overflow-x-auto   h-screen">
            <div v-motion-slide-top
                class="m-5 md:m-6  lg:w-[80%] xl:w-[80%] lg:mx-auto  bg-white shadow-sm  p-3 overflow-auto space-y-4">

                <div class="w-full mx-auto flex  items-center justify-between mb-4">
                    <h1 class="text-primery1 text-heading3">
                        {{ datatoedit ? "កែប្រែសេវ៉ាកម្មអាជីវក​ម្ម" : "បន្ថែមសេវ៉ាកម្មអាជីវក​ម្ម" }}

                    </h1>
                    <button @click="handleClose"
                        class="w-10 h-10 rounded-full focus:border-primery1 focus:border flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"
                            class="w-8 h-8 text-primery1">
                            <path
                                d="M5.28 4.22a.75.75 0 0 0-1.06 1.06L6.94 8l-2.72 2.72a.75.75 0 1 0 1.06 1.06L8 9.06l2.72 2.72a.75.75 0 1 0 1.06-1.06L9.06 8l2.72-2.72a.75.75 0 0 0-1.06-1.06L8 6.94 5.28 4.22Z" />
                        </svg>
                    </button>
                </div>
                <div class="relative w-full overflow-x-auto bg-gray-50 border h-[150px]">
                    <table v-if="productFeatures.length > 0">
                        <tr>
                            <td class="text-center">ល.រ</td>
                            <td>លក្ខណៈ</td>
                            <td>លំអិត</td>
                            <td>រូបភាព</td>
                            <th class="text-center">សកម្មភាព</th>
                        </tr>
                        <tr v-for="(feature, index) in productFeatures" :key="index">
                            <td class="text-center w-[50px]">{{ index + 1 }}</td>
                            <td>{{ feature.title }}</td>
                            <td class="w-[700px]">{{ feature.details }}</td>
                            <td class="w-[80px]">
                                <img width="50px" :src="feature.image" />
                            </td>
                            <td>
                                <div class="flex w-full h-full gap-3 items-center justify-center">
                                    <button @click="editFeature(index, feature)"
                                        class="w-[36px] flex items-center justify-center h-[36px] bg-accent rounded-[6px]">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                            class="w-6 h-6 text-white">
                                            <path
                                                d="m5.433 13.917 1.262-3.155A4 4 0 0 1 7.58 9.42l6.92-6.918a2.121 2.121 0 0 1 3 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 0 1-.65-.65Z" />
                                            <path
                                                d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0 0 10 3H4.75A2.75 2.75 0 0 0 2 5.75v9.5A2.75 2.75 0 0 0 4.75 18h9.5A2.75 2.75 0 0 0 17 15.25V10a.75.75 0 0 0-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5Z" />
                                        </svg>
                                    </button>
                                    <button @click="handleRemoveFeature(index)"
                                        class="w-[36px] flex items-center justify-center h-[36px] bg-red-500 rounded-[6px]">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                            class="w-6 h-6 text-white">
                                            <path fill-rule="evenodd"
                                                d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <div v-else
                        class="relative w-[600px] mx-auto flex items-center justify-center text-primery1 font-NotoSansKhmer text-body h-full overflow-x-auto sm:rounded-lg">
                        <h2 class="text-center">មិនទាន់មាន</h2>
                    </div>
                </div>

                <div class="flex justify-between gap-4 overflow-x-auto w-full">
                    <form @submit.prevent="handleSubmit" class="w-1/2 space-y-2 items-center justify-center border p-4">
                        <div class="w-full flex flex-wrap md:flex-nowrap gap-2">
                            <div class="w-full space-y-2">
                                <label class="text-body text-textbody">ឈ្មោះប្រព័ន្ធគ្រប់គ្រង: * </label>

                                <input required v-model="bussinessName" type="text"
                                    class="border p-2 w-full hover:border-primery1" placeholder="ឈ្មោះប្រព័ន្ធគ្រប់គ្រង" />
                            </div>


                        </div>
                        <div class="w-full">

                            <div>
                                <label class="text-body text-textbody"> លំអិតពីអាជីវកម្ម </label>
                                <textarea type="text" required v-model="bussinessDescription"
                                    placeholder="លំអិតពីអាជីវកម្ម" class="border p-2 w-full hover:border-primery1"
                                    rows="5"></textarea>



                            </div>
                        </div>
                        <!-- <div class="w-full space-y-2">
                            <label class="text-body text-textbody"> រូបភាពផលិតផល </label>

                            <div
                                class="hover:border-primery1 border-[1px] w-full  border-dashed relative overflow-auto h-[165px]">
                                <h2 v-if="!pre_image"
                                    class="text-center text-lebeltext text-heading4 overflow-auto cursor-pointer">
                                    រូបភាពផលិតផល
                                </h2>
                                <div v-else>
                                    <img :src="pre_image" alt="Product Image" class="w-full h-full" />
                                </div>
                                <input @change="handleFileChangeProductImage" type="file"
                                    class="opacity-0 absolute w-full cursor-pointer z-50" />
                            </div>
                        </div> -->

                        <div class="w-full space-y-2">
                            <label class="text-body text-textbody py-2"> រូបភាព </label>
                            <div
                                class="cursor-pointer h-[130px] w-[130px] border-[1px] hover:border-primery1 border-dashed flex items-center justify-center relative overflow-auto">
                                <h2 v-if="!pre_image"
                                    class="text-center text-lebeltext text-heading4 overflow-auto cursor-pointer">
                                    <img src="https://fakeimg.pl/90x90/" class="w-[90px] h-[90px]" alt="">
                                </h2>
                                <div v-else class="h-[200px] w-full overflow-auto overflow-x-auto">
                                    <img :src="pre_image" alt="Selected Image"
                                        class="pt-10 items-center object-contain" />
                                </div>

                                <img v-if="productImage" :src="productImage" alt="">

                                <input @change="handleFileChangeProductImage" type="file"
                                    class="opacity-0 absolute h-full w-[90%] cursor-pointer" />
                            </div>
                        </div>

                        <div class="w-full space-y-2">
                            <label class="text-body text-textbody py-2">វីដេអូ</label>
                            <div
                                class="cursor-pointer h-[350px] w-[350px] border-[1px] hover:border-primary1 border-dashed flex items-center justify-center relative overflow-auto">
                                <!-- Placeholder Image -->
                                <div v-if="!selectVideo && !file_video"
                                    class="text-center text-lebeltext text-heading4 overflow-auto cursor-pointer">
                                    <img :src="videoThumbnail || placeholderImage || 'https://d2uolguxr56s4e.cloudfront.net/img/kartrapages/video_player_placeholder.gif'"
                                        class="w-[300px] h-[300px] object-contain" alt="Placeholder Image" />
                                </div>

                                <!-- Video Preview -->
                                <video v-if="selectVideo || file_video" :src="selectVideo || file_video" controls
                                    class="absolute h-full w-full object-contain"></video>

                                <!-- File Input -->
                                <input type="file" class="opacity-0 absolute h-full w-[90%] cursor-pointer"
                                    @change="handleChangeVideo" accept="video/*" />
                            </div>
                        </div>



                        <div v-if="loading == false" class="w-auto flex justify-end mx-auto">
                            <button
                                class="px-10 p-3 bg-primery1 transition-all duration-300 ease-in-out hover:bg-blue-500 text-white mt-2">
                                {{ datatoedit ? "កែប្រែ" : "បន្ថែមថ្មី" }}
                            </button>
                        </div>
                        <div v-else class="w-auto flex justify-end mx-auto">
                            <button class="px-5 p-3 bg-primery1 items-center  flex  text-white">
                                <svg aria-hidden="true" role="status"
                                    class="inline w-6 h-6 me-3 text-white animate-spin" viewBox="0 0 100 101"
                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="#E5E7EB" />
                                    <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentColor" />
                                </svg>
                                កំពុងបញ្ចូល
                            </button>
                        </div>
                    </form>
                    <div class="relative w-1/2 overflow-x-auto">
                        <div class="w-full border h-full p-4  space-y-2">
                            <form @submit.prevent="handleAddFeature" class="space-y-2">
                                <label class="text-body text-textbody">
                                    ចំណងជើងលក្ខណៈផលិតផល
                                </label>

                                <div class="flex w-full gap-2">
                                    <input required v-model="productFeaturesTitle" type="text"
                                        class="w-full border p-2 pl-2 hover:border-primery1"
                                        placeholder="ចំណងជើងលក្ខណៈផលិតផល" />
                                </div>
                                <label class="text-body text-textbody"> រូបភាព </label>
                                <div class="flex w-full bg-background gap-2">
                                    <div
                                        class="w-full hover:border-primery1 border-[1px] border-dashed relative h-full ">
                                        <div v-if="!image_feature" class="text-center text-lebeltext text-heading4">
                                            <img :src="img || 'https://fakeimg.pl/660x400/'" />

                                        </div>
                                        <div v-else>
                                            <img :src="image_feature" alt="Product Image" class="w-full h-full" />
                                        </div>
                                        <input @change="handleFileChange" type="file" accept="image/*"
                                            class="opacity-0 absolute top-0 w-full h-full cursor-pointer bg-neutral-500" />

                                        <!-- <img v-if="img" :src="img" alt=""> -->



                                    </div>
                                </div>

                                <!-- <input @change="handleFileChange" type="text" v-model="img" > -->

                                <label class="text-body text-textbody">
                                    លក្ខណៈផលិតផលពីលំអិត
                                </label>
                                <textarea rows="5" cols="50"  v-model="productFeatueDetails"
                                    class="w-full border-[1px] border-dashed p-2 hover:border-primery1"
                                    placeholder="លក្ខណៈផលិតផលពីលំអិត" />

                                <div class="w-auto flex justify-end mx-auto">
                                    <button
                                        class="bg-primery1 p-3 px-10  hover:bg-blue-500  transition-all duration-300 ease-in-out text-white">
                                        {{ datatoedit ? "កែប្រែ" : "បញ្ចូល" }}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import useCollection from "@/composible/useCollection";
import useStorage from "@/composible/useStorange";
import { push } from "notivue";
import { ref, onMounted } from "vue";
import { timestamp } from "@/firebase/config";
import { getCollectionQuery } from "@/composible/getCollection";
export default {
    emit: ["AddSusccesfully", "UpddateSuccess"],
    props: ["datatoedit"],
    setup(props, { emit }) {
        const category = ref([]);
        const itemsType = ref("");
        const getDataCategory = async () => {
            try {
                await getCollectionQuery(
                    "categories",
                    [],
                    (data) => {
                        category.value = data;
                    },
                    true
                );
            } catch (error) {
                console.error(error.message);
            }
        };

        const ispending = ref(false);
        const { addDocs, removeDoc, updateDocs } = useCollection("bussiness");
        const { uploadImage } = useStorage();
        const bussinessName = ref("");
        const productDesscript = ref("");
        const bussinessDescription = ref("")
        // const addproductDesscript = ref("");


        const productImage = ref(null);


        const productFeatures = ref([]);
        const productFeaturesTitle = ref("");
        const productFeatueDetails = ref("");

        const img = ref(null);
        const pre_image = ref(null)
        const image_feature = ref(null)


        const productDisplay = ref("");
        const productDisplayStanding = ref(["outstanding", "normal"]);

        const selectVideo = ref(null)
        const file_video = ref(null)


        const handleFileChange = (event) => {
            try {
                const file = event.target.files[0];
                if (!file) {
                    console.error("No file selected.");
                    return;
                }
                const allowedExtensions = ["jpg", "png", "svg", "jpeg"];
                const extension = file.name.split(".").pop().toLowerCase();

                if (!allowedExtensions.includes(extension)) {
                    console.error("Only jpg, png, svg, and jpeg files are allowed.");
                    return;
                }
                image_feature.value = URL.createObjectURL(file)
                img.value = file;
            }
            catch (err) {
                console.log(err)
            }
        };

        //handle change video 

        const handleChangeVideo = (e) => {
            try {
                const file = e.target.files[0];
                selectVideo.value = URL.createObjectURL(file)
                file_video.value = file;
                console.log(selectVideo.value);
            }
            catch (err) {
                console.log(err)
            }

        }

        const handleFileChangeProductImage = (event) => {
            try {
                const file = event.target.files[0];
                if (!file) {
                    console.error("No file selected.");
                    return;
                }
                const allowedExtensions = ["jpg", "png", "svg", "jpeg"];
                const extension = file.name.split(".").pop().toLowerCase();

                if (!allowedExtensions.includes(extension)) {
                    console.error("Only jpg, png, svg, and jpeg files are allowed.");
                    return;
                }
                pre_image.value = URL.createObjectURL(file)
                productImage.value = file;
                console.log(pre_image.value)
            }
            catch (err) {
                console.log(err)
            }
        };

        const loading = ref(false);
        const handleSubmit = async () => {
            loading.value = true;
            try {
                let imageURL = null;
                let videoURL = null;

                if (productImage.value && productImage.value !== props.datatoedit?.image) {
                    // Check image size
                    if (productImage.value.size > 1024 * 1024) {
                        console.error("Image size exceeds 1MB limit.");
                        return;
                    }

                    // Upload image
                    const storagePath = `bussiness/${productImage.value.name}`;
                    imageURL = await uploadImage(storagePath, productImage.value);
                } else {
                    // If img.value hasn't changed or is not provided, retain the existing image URL
                    imageURL = props.datatoedit?.image;
                }

                if (file_video.value && file_video.value !== props.datatoedit?.video) {
                    // Check video size
                    if (file_video.value.size > 15 * 1024 * 1024) { // 5MB limit
                        console.error("Video size exceeds 15MB limit.");
                        return;
                    }

                    // Upload video
                    const storagePathVideo = `Video/${file_video.value.name}`;
                    videoURL = await uploadImage(storagePathVideo, file_video.value);
                } else {
                    // If video hasn't changed or is not provided, retain the existing video URL
                    videoURL = props.datatoedit?.video;
                }

                const productData = {
                    name: bussinessName.value,
                    bussinessDescription: bussinessDescription.value,
                    feature: productFeatures.value,
                    image: imageURL,
                    video: videoURL,
                    createdAt: timestamp(),
                };

                if (props.datatoedit?.id) {
                    const updateSuccess = await updateDocs(props?.datatoedit?.id, productData);
                    if (updateSuccess) {
                        push.success("Update Successful");
                    }
                    console.log(updateSuccess, "Product operation successful");
                } else {
                    const success = await addDocs(productData);
                    ispending.value = true;
                    if (success) {
                        emit("handleUpdateSuccess");
                    }
                    push.success("Save Successful");
                }

              
                console.log(productData);
                handleClose();
            } catch (error) {
                console.error("Error performing product operation:", error);
                push.error("Please upload file and Video")
            } finally {
                loading.value = false; // Ensure loading is set to false after completion
            }
        };


        const handleAddFeature = async () => {
            try {
                let imageURL = null;

                if (img.value && img.value !== props.datatoedit?.image) {
                    // Check image size
                    if (img.value.size > 1024 * 1024) {
                        console.error("Image size exceeds 1MB limit.");
                        return;
                    }

                    // Upload image
                    const storagePath = `feature/${img.value.name}`;
                    imageURL = await uploadImage(storagePath, img.value);
                } else {
                    // Retain the existing image URL if no new image is provided or the image hasn't changed
                    imageURL = props.datatoedit?.image;
                }

                const newFeature = {
                    title: productFeaturesTitle.value,
                    details: productFeatueDetails.value,  // Corrected typo
                    image: imageURL,
                };

                // Reset form fields and update the features list
                image_feature.value = null;
                productFeatures.value.push(newFeature);
                productFeaturesTitle.value = "";
                productFeatueDetails.value = "";  // Corrected typo
                img.value = null;

                alert("Feature added successfully");
                console.log(newFeature);
            } catch (error) {
                console.error("Error adding feature:", error);
            }
        };


        const editFeature = (index, item) => {
            // Check if the provided index is valid
            if (index >= 0 && index < productFeatures.value.length) {
                // Store the old image
                const oldImage = productFeatures.value[index].image;

                // Remove the item at the specified index
                productFeatures.value.splice(index, 1);

                // Update the text values with the properties of the item being edited
                productFeaturesTitle.value = item.title;
                productFeatueDetails.value = item.details;

                // Restore the old image if it exists
                if (oldImage !== null && oldImage !== undefined) {
                    img.value = oldImage;
                }
            }
        };

        onMounted(() => {
            getDataCategory();
        });
        onMounted(() => {
            if (props.datatoedit) {
                bussinessName.value = props.datatoedit.name;
                productFeatures.value = props.datatoedit.feature;
                itemsType.value = props.datatoedit.type;
                bussinessDescription.value = props.datatoedit.bussinessDescription;
                productDesscript.value = props.datatoedit.descritpts;
                productDisplay.value = props.datatoedit.productdisplay;
                productImage.value = props.datatoedit.image;
                file_video.value = props.datatoedit.video;

            }
        });
        const currentComponent = ref("");
        const handleClose = () => {
            currentComponent.value = "";
            emit("close");
        };

        const handleRemoveFeature = async (index, productId) => {
            productFeatures.value.splice(index, 1);

            try {
                await updateDocs("products", productId, {
                    productFeatures: productFeatures.value,
                });

                console.log("Feature removed successfully from Firebase");
            } catch (error) {
                console.error("Error removing feature from Firebase:", error);
            }
        };

        return {
            handleClose,
            handleAddFeature,
            handleFileChange,
            productFeatueDetails,
            productFeaturesTitle,
            productFeatures,
            bussinessName,
            img,
            itemsType,
            handleSubmit,
            currentComponent,
            productDisplayStanding,
            handleRemoveFeature,
            editFeature,
            push,
            productDesscript,
            ispending,
            handleFileChangeProductImage,
            productImage,
            loading,
            category,
            bussinessDescription,
            pre_image,
            image_feature,
            selectVideo,
            handleChangeVideo,
            file_video

        };
    },
};
</script>

<style scoped>
/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #510192b9;
    border-radius: 10px;
    margin-left: 20px;
    transition: 3s;
}

::-webkit-scrollbar-thumb:hover {
    display: block;
    transition: 0.5s;
}

table,
tr,
th,
td {
    border: 1px solid lightgray;
    border-collapse: collapse;


}
</style>