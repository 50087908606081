<template>
    <swiper :spaceBetween="30" :centeredSlides="true" :autoplay="{
        delay: 2500,
        disableOnInteraction: false
    }" :pagination="{
        clickable: true
    }" :navigation="true" :modules="modules" class="mySwiper" ref="mySwiper" @slideChange="onSlideChange"
        @transitionEnd="onTransitionEnd">
        <!-- Custom Video 1 -->
        <swiper-slide>
            <video autoplay muted playsinline>
                <source src="../../src/assets/System VDO-for Website(Done).mp4" type="video/mp4" />
            </video>
        </swiper-slide>

        <!-- Custom Video 2 -->
        <swiper-slide>
            <video autoplay muted playsinline>
                <source src="https://into-the-program.com/uploads/sample_video02.mp4" type="video/mp4" />
            </video>
        </swiper-slide>

        <!-- Custom Video 3 -->
        <swiper-slide>
            <video autoplay muted playsinline>
                <source src="https://into-the-program.com/uploads/sample_video03.mp4" type="video/mp4" />
            </video>
        </swiper-slide>
    </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';

import { ref, onMounted } from 'vue';
import { Navigation } from 'swiper/modules';

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        const mySwiper = ref(null);

        const muteAllVideos = () => {
            const videos = document.querySelectorAll('video');
            videos.forEach((video) => {
                video.muted = true;
                video.pause();
            });
        };

        const playActiveVideo = () => {
            const swiper = mySwiper.value.swiper;
            if (!swiper) return;

            const activeSlide = swiper.slides[swiper.realIndex];
            const activeVideo = activeSlide.querySelector('video');

            if (activeVideo) {
                activeVideo.muted = false; // Unmute the active video
                activeVideo.play(); // Play the active video
            }
        };

        const onSlideChange = () => {
            muteAllVideos();
            playActiveVideo();
        };

        const onTransitionEnd = () => {
            playActiveVideo();
        };

        onMounted(() => {
            if (mySwiper.value) {
                console.log('Swiper initialized:', mySwiper.value.swiper);
                // Ensure the first video plays on mount
                playActiveVideo();
            }
        });

        return {
            mySwiper,
            onSlideChange,
            onTransitionEnd,
            modules: [Navigation],
        };
    },
};
</script>
