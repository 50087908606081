<template>
    <div v-motion :initial="{
        opacity: 0,
    }" :enter="{

        opacity: 1,
        transition: {
            duration: 500,

            ease: 'easeIn',
        },
    }">
        <div class="lg:w-[90%] xl:w-[1280px] mx-auto my-10 md:px-4 lg:px-4">


            <div>
                <div class="w-full mb-8 lg:w-[80%] px-5 lg:px-0">
                    <h1 class="text-start md:text-[26px] font-NotoSansKhmer font-bold text-primery1 xl:text-[28px] ">
                        ឧបករណ៍ទាំងអស់
                    </h1>
                    <p
                        class="text-[16px] mt-2 text-justify font-[500] md:text-[18px]  xl:mx-0 lg:text-[16px] text-black/70 xl:text-[18px] xl:mb-10">
                        ឈុតឧបករណ៍ ប្រើក្នុងឈុតប្រព័ន្ធគ្រប់គ្រងអាជីវកម្ម POS Hardware បែបទំនើបស្តងដារ​ ទាន់សម័យ
                        ងាយស្រួលប្រើប្រាស់ ទៅតាមតម្រូវការដែលអតិថិជនចង់បាន។
                    </p>
                    <div class="flex flex-wrap gap-4 mt-5">
                        <button v-for="cate in hardwareCategory" :key="cate.id" @click="selectCategory(cate.id)"
                            class="px-4 py-2   hover:bg-indigo-700 duration-300 ease-in-out rounded-md transition hover:text-white font-poppins text-sm md:text-md"
                            :class="{
                                'bg-gradient-to-r from-[#500192] to-indigo-600 text-white ': selectedCategory && selectedCategory.id === cate.id,
                                'bg-gray-200 ': !selectedCategory || selectedCategory.id !== cate.id,
                            }">
                            {{ cate.categoryName }}
                        </button>
                    </div>
                </div>

                <!-- Selected Category Products -->
                <div v-if="selectedCategory" class="mt-4 md:px-5 lg:px-0 px-5">
                    <h2 class="font-[500] text-[20px] capitalize font-poppins ">{{ selectedCategory.categoryName }}</h2>
                    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-4">

                        <div v-for="pro in selectedCategory.hardware" :key="pro">
                            <div class="relative bg-white h-full rounded-md cursor-pointer overflow-hidden hover:scale-105 duration-300 transition-all"
                                style="box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;">
                                <div
                                    class="absolute inset-0 border-2 border-transparent rounded-md hover:border-indigo-500 transition-all duration-500 animate-border-animation">
                                </div>
                                <div class="p-[16px] gap-6">
                                    <div class="flex justify-center">
                                   
                                        <!-- <img :src="pro.url"
                                            class="h-[100px] w-[100px] md:w-[140px] md:h-[140px] lg:w-[120px] lg:h-[120px] xl:w-[180px] xl:h-[180px] object-contain"
                                            alt=""> -->

                                            <img src="https://fakeimg.pl/180x180/"
                                            class="h-[100px] w-[100px] md:w-[140px] md:h-[140px] lg:w-[120px] lg:h-[120px] xl:w-[180px] xl:h-[180px] object-contain"
                                            alt="">
                                    </div>

                                    <div class="mt-2">
                                        <h4
                                            class="font-bold font-poppins md:text-[18px] xl:text-[18px] lg:text-[15px] text-[16px] line-clamp-2">
                                            {{ pro.productName }}
                                        </h4>
                                
                                        <div v-html="pro.productDesscript"
                                            class="xl:text-[16px] font-poppins prose mt-[12px] md:text-[16px] xl:mt-[16px] lg:text-[#909090]  lg:text-[12px]  text-[14px]">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useFirestoreCollection, useSubcollection } from "@/composible/getSubcollection";
import { ref } from "vue";
import { onMounted } from "vue";

export default {
    setup() {
        const hardwareCategory = ref([]);
        const selectedCategory = ref(null);
        const isLoading = ref(false);
        const error = ref(null);

        const { documents: categoryDocument, fetchCollection } = useFirestoreCollection("hardwareCategories");

        onMounted(async () => {
            isLoading.value = true;
            try {
                await fetchCollection();
                await fetchCategoryHardware();
                // Automatically select the first category
                if (hardwareCategory.value.length > 0) {
                    selectedCategory.value = hardwareCategory.value[0];
                }
            } catch (err) {
                error.value = "Failed to load categories. Please try again.";
                console.error(err);
            } finally {
                isLoading.value = false;
            }
        });

        const fetchCategoryHardware = async () => {
            const orderByField = "productName";
            try {
                const categoryPromises = categoryDocument.value.map(async (cate) => {
                    const { subcollectionData: product, fetchSubcollection } = useSubcollection(
                        "hardwareCategories",
                        cate.id,
                        "hardware",
                        orderByField
                    );
                    await fetchSubcollection();
                    return {
                        id: cate.id,
                        categoryName: cate.name,
                        hardware: product.value,
                    };
                });

                const result = await Promise.all(categoryPromises);
                hardwareCategory.value = result;
            } catch (err) {
                console.error("Error fetching categories and products:", err);
                throw err;
            }
        };

        const selectCategory = (categoryId) => {
            selectedCategory.value = hardwareCategory.value.find((cate) => cate.id === categoryId);
        };

        return { hardwareCategory, selectedCategory, selectCategory, isLoading, error };
    },
};
</script>